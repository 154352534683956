import { Divider, ListItemIcon, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { User } from '../../model/UserInterface';
import { resetLogin } from '../../pages/Login/loginSlice';
import { removeToken } from '../../utility/tokenSlice';
import { isAdmin } from '../../utility/token';
import ModifyUserModalComponent from '../Modal/ModifyUserModalComponent';
import { BarChart, Home, Logout, Settings, VpnKey } from '@mui/icons-material';
import { disconnectWebSocket } from '../../utility/socket/socket';
import { REACT_APP_WS_URL } from '../../config';
import { resetNotification } from '../../utility/socket/notificationSlice';

const UserStyleMenuComponent = (user: User) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const [
    openModifyUserPasswordModalVisible,
    setOpenModifyUserPasswordModalVisible,
  ] = useState(Boolean);

  const handleLogout = () => {
    dispatch(removeToken());
    dispatch(resetLogin());
    dispatch(resetNotification());
    localStorage.removeItem('USER');
    localStorage.removeItem('notifications');
    disconnectWebSocket(REACT_APP_WS_URL);
    navigate('/login');
  };
  const handleCloseModifyUserPasswordModal = () => {
    setOpenModifyUserPasswordModalVisible(false);
  };

  const navigateToHome = () => {
    return (
      <MenuItem
        onClick={() => {
          navigate('/home');
        }}
        disableRipple
      >
        <ListItemIcon>
          <Home />
        </ListItemIcon>
        <span>Home</span>
      </MenuItem>
    );
  };

  const navigateToUsers = () => {
    return (
      <MenuItem
        onClick={() => {
          navigate('/users');
        }}
        disableRipple
      >
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <span>Lista Utenti</span>
      </MenuItem>
    );
  };

  const navigateToDashboard = () => {
    return (
      <MenuItem
        onClick={() => {
          navigate('/dashboard');
        }}
        disableRipple
      >
        <ListItemIcon>
          <BarChart />
        </ListItemIcon>
        <span>Dashboard</span>
      </MenuItem>
    );
  };

  const navigateToChangePassword = () => {
    return (
      <MenuItem
        onClick={() => {
          setOpenModifyUserPasswordModalVisible(true);
        }}
        disableRipple
      >
        <ListItemIcon>
          <VpnKey />
        </ListItemIcon>
        <span>Cambia Password</span>
      </MenuItem>
    );
  };

  const listMenuByRoles = () => {
    if (!isAdmin()) return <>{navigateToChangePassword()}</>;

    if (pathname === '/home' || pathname === '/') {
      return (
        <>
          {navigateToDashboard()}
          {navigateToUsers()}
        </>
      );
    }

    if (pathname === '/users') {
      return (
        <>
          {navigateToHome()}
          {navigateToDashboard()}
        </>
      );
    }

    if (pathname === '/dashboard') {
      return (
        <>
          {navigateToHome()}
          {navigateToUsers()}
        </>
      );
    }
  };

  return (
    <>
      {listMenuByRoles()}
      <Divider sx={{ my: 0.5 }} />
      <MenuItem disableRipple onClick={handleLogout}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <span>Logout</span>
      </MenuItem>
      {openModifyUserPasswordModalVisible && (
        <ModifyUserModalComponent
          open={openModifyUserPasswordModalVisible}
          onClose={() => handleCloseModifyUserPasswordModal()}
          rowUser={user?.user}
        />
      )}
    </>
  );
};

export default UserStyleMenuComponent;
