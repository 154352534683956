import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { REACT_APP_API_URL } from '../../config';
import { _getConfig } from '../../utility/headers';
import { TypeState } from '../../model/typeInterface';

//*-1- GET ALL -- /stonetype/id/{id}

const initialState: TypeState = {
  typeLoad: {
    data: null,
    status: 'idle',
  },
};

//*-1- GET ALL
export const getAllTypeAsync = createAsyncThunk(
  'type/getAllType',
  async (stoneType: string) => {
    try {
      const response: any = await axios
        .get(`${REACT_APP_API_URL}/stonetype/id/${stoneType}`, _getConfig(true))
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

export const typeSlice = createSlice({
  name: 'type',
  initialState,
  reducers: {
    resetTypeLoad: (state) => {
      state.typeLoad.status = 'idle';
      state.typeLoad.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTypeAsync.pending, (state) => {
        state.typeLoad.status = 'loading';
      })
      .addCase(getAllTypeAsync.fulfilled, (state: any, action) => {
        state.typeLoad.status = 'success';
        state.typeLoad.data = action.payload;
      })
      .addCase(getAllTypeAsync.rejected, (state: any, action) => {
        state.typeLoad.status = 'failed';
        state.typeLoad.data = action.error;
      });
  },
});

export const { resetTypeLoad } = typeSlice.actions;
export const typeLoadStatus = (state: RootState) => state.type.typeLoad;
export default typeSlice.reducer;