import _ from 'lodash';
import { StonesObject } from '../../model/stonesInterface';

const createCounterArr = (
  dataArray: Array<StonesObject>,
  stringArray: string[],
  innerPropPath: any
) => {
  const arrCounter: any = [];
  const arrFilter: any = [];
  for (let i = 0; i < stringArray.length; i++) {
    const result: any = new Object();
    const string = stringArray[i];
    const filteredArray = dataArray.filter(
      (item: any) => item?.steps?.[0]?.stepId === string
    );
    result[string] = {
      data: filteredArray,
      counter: filteredArray.length,
    };
    arrFilter.push(result);
  }

  for (const obj in arrFilter) {
    for (const count in arrFilter[obj]) {
      arrCounter.push(_.at(arrFilter[obj][count], innerPropPath)[0]);
    }
  }
  return arrCounter;
};

export { createCounterArr };
