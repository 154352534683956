import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
} from '@mui/material';
import { TableComponentInterface } from '../../model/tableComponentInterface';
import _ from 'lodash';
import StyledTableCell from './StyledTableCell';
import { useState } from 'react';
import { deepPathValue } from '../../utility/deepPathValue/deepPathValue';

const TableComponent = ({
  data,
  dataStep,
  columns,
  stepSelected,
  titleHover,
  renderCell,
  sortable = false,
}: TableComponentInterface) => {
  const tableStyleHead = dataStep?.props?.tableStyle?.tableHead;
  const [order, setOrder] = useState<any>('asc');
  const [orderBy, setOrderBy] = useState<any>();

  const handleSort = (property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData: any = data?.sort((a: any, b: any) => {
    const isAsc = order === 'asc';
    let valueX: any = _.at(a, orderBy)[0];
    let valueY: any = _.at(b, orderBy)[0];

    if(orderBy?.match(/\(([^)]+)\)/g)){
      const deepValueX = deepPathValue(a, orderBy);
      const deepValueY = deepPathValue(b, orderBy);
      valueX = deepValueX;
      valueY = deepValueY;

    }

    if (valueX === undefined) return isAsc ? 1 : -1;
    if (valueY === undefined) return isAsc ? -1 : 1;
    return isAsc ? (valueX > valueY ? 1 : -1) : valueX < valueY ? 1 : -1;
  });

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" className="prettyTable">
          <TableHead>
            <TableRow>
              {columns?.map((column: any, i: number) => (
                <StyledTableCell
                  key={i}
                  align={'center'}
                  style={tableStyleHead}
                >
                  {sortable && column?.path !== 'actions' ? (
                    <TableSortLabel
                      className="tableSortLabel"
                      active={orderBy === column?.path}
                      direction={orderBy === column?.path ? order : 'asc'}
                      onClick={() => handleSort(column?.path)}
                    >
                      {column?.label ?? 'N/D'}
                    </TableSortLabel>
                  ) : (
                    column?.label ?? 'N/D'
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item: any, i: number) => {
                const tableStyleBody = dataStep?.props?.tableStyle?.tableBody;
                return (
                  <TableRow hover tabIndex={-1} key={i}>
                    {columns?.map((columnKey: any, i: number) => (
                      <TableCell
                        title={titleHover(item, columnKey)}
                        className={
                          columnKey?.path !== 'actions'
                            ? 'tableCell'
                            : undefined
                        }
                        align={'center'}
                        key={i}
                        style={tableStyleBody}
                      >
                        {renderCell(item, columnKey, stepSelected)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {data?.length === 0 ? (
        <p style={{ textAlign: 'center', margin: '15px' }}>Non ci sono dati qui.</p>
      ) : null}
    </>
  );
};

export default TableComponent;
