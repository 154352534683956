// import { _getType } from './utility/typeApp';

export const NODE_ENV = process.env.NODE_ENV;
export const HOST = process.env.HOST;
export const PORT = process.env.PORT;
export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const TYPE = process.env.REACT_APP_TYPE;
document.title = `${process.env.REACT_APP_TYPE}`;

export const REACT_APP_WS_URL = process.env.REACT_APP_WS_URL;
