import './App.css';
import Login from './pages/Login/Login';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import GeneralView from './pages/GeneralView/GeneralView';
import ErrorPage from './pages/Error/ErrorPage';
import IsAuth from './pages/Auth/IsAuth';
import Users from './pages/Users/Users';
import Dashboard from './pages/Dashboard/Dashboard';
import { getAllStonesAsync } from './pages/GeneralView/stonesSlice';
import { useAppDispatch } from './app/hooks';
import { useEffect } from 'react';
import { TYPE } from './config';
import { stompSocket } from './utility/socket/socket';
import { getToken } from './utility/token';
import { getAllStepsAsync } from './pages/GeneralView/stepsSlice';
/* import { CssBaseline, createTheme } from '@mui/material'; */
import { subscribeClientHandle } from './utility/socket/subscribeClient';
import { socketMessageParsed } from './utility/socket/messageParsed';
import { setNotification } from './utility/socket/notificationSlice';
import { notificationCheck } from './utility/socket/notificationCheckLocalStorage';

/* const theme =    createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background-color: #F5F5F5;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #c1c1c1;
          border-radius: 4px;
        }
        ::-webkit-scrollbar-track {
          background-color: #F5F5F5;
        }
      `,
    },
  },
});
 */

const App = () => {
  const dispatch = useAppDispatch();
  
  const socketNotify: any = (msg: any) => {
    dispatch(getAllStonesAsync(`${TYPE}`));
    dispatch(setNotification(socketMessageParsed(msg)));
    notificationCheck(msg);
  };

  const router = createBrowserRouter([
    {
      path: '*',
      element: <ErrorPage />,
    },
    {
      path: '/login',
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/users',
      element: <Users />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/dashboard',
      element: <Dashboard />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/',
      element: <IsAuth />,
      errorElement: <Login />,
      children: [
        {
          path: '/home',
          element: <GeneralView />,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ]);

  //*-- STOMP JS SOCKET on INIT
  useEffect(() => {
    if (!localStorage.getItem('jwtToken')) return;
    let steps: any;
    dispatch(getAllStepsAsync(`${TYPE}`))
      .unwrap()
      .then((res: any) => {
        steps = res;
      })
      .catch(() => {
        // console.log(err);
      });
    const subscription: any = stompSocket('/queue', getToken(), (msg: any) =>
      subscribeClientHandle(msg, steps) ? socketNotify(msg) : null
    );
    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
