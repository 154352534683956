import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { REACT_APP_API_URL } from '../../config';
import { StepsState } from '../../model/stepsInterface';
import { _getConfig } from '../../utility/headers';

//*-1- GET ALL BY TYPE  --  /steps/type/{type}

//*-2- GET SINGLE BY TYPE AND ID  -- /steps/type/{type}

//*-3- POST ADD STEP  -- /steps/type/{type}/{id}

//*-4- PUT SINGLE BY TYPE AND ID  -- /steps/type/{type}/{id}

//*-5- DELETE SINGLE BY TYPE AND ID  -- /steps/type/{type}/{id}

const initialState: StepsState = {
  stepsLoad: {
    data: null,
    status: 'idle',
  },
  stepsLoadSingle: {
    data: null,
    status: 'idle',
  },
  stepsAdd: {
    data: null,
    status: 'idle',
  },
  stepsMod: {
    data: null,
    status: 'idle',
  },
  stepsRemove: {
    data: null,
    status: 'idle',
  },
};

//*-1- GET ALL BY TYPE  --  /steps/type/{type}
export const getAllStepsAsync = createAsyncThunk(
  'steps/getAllSteps',
  async (type: string) => {
    try {
      const response: any = await axios
        .get(`${REACT_APP_API_URL}/steps/type/${type}`, _getConfig(true))
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

//*-2- GET SINGLE BY TYPE AND ID  -- /steps/type/{type}/{id}
export const getSingleStepsAsync = createAsyncThunk(
  'steps/getSingleSteps',
  async ({ type, id }: { type: string; id: string }) => {
    try {
      const response: any = await axios
        .get(`${REACT_APP_API_URL}/steps/type/${type}/${id}`, _getConfig(true))
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

//*-3- POST ADD STEP  -- /steps/type/{type}/{id}
export const postStepsAsync = createAsyncThunk(
  'steps/postSteps',
  async ({body, type }: {body: any; type: string}) => {
    try {
      const response: any = await axios
        .post(
          `${REACT_APP_API_URL}/steps/type/${type}`,
          body,
          _getConfig(true)
        )
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

//*-4- PUT SINGLE BY TYPE AND ID  -- /steps/type/{type}/{id}
export const putStepsAsync = createAsyncThunk(
  'steps/putSteps',
  async ({ body, type, id }: { body: any; type: string; id: string }) => {
    try {
      const response: any = await axios
        .put(
          `${REACT_APP_API_URL}/steps/type/${type}/${id}`,
          body,
          _getConfig(true)
        )
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

//*-5- DELETE SINGLE BY TYPE AND ID  -- /steps/type/{type}/{id}
export const deleteStepsAsync = createAsyncThunk(
  'steps/deleteSteps',
  async ({ type, id }: { type: any; id: string }) => {
    try {
      const response: any = await axios
        .delete(
          `${REACT_APP_API_URL}/steps/type/${type}/${id}`,
          _getConfig(true)
        )
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

export const stepsSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    resetStepsLoad: (state) => {
      state.stepsLoad.status = 'idle';
      state.stepsLoad.data = null;
    },
    resetStepsLoadSingle: (state) => {
      state.stepsLoadSingle.status = 'idle';
      state.stepsLoadSingle.data = null;
    },
    resetStepsAdd: (state) => {
      state.stepsAdd.status = 'idle';
      state.stepsAdd.data = null;
    },
    resetStepsMod: (state) => {
      state.stepsMod.status = 'idle';
      state.stepsMod.data = null;
    },
    resetStepsRemove: (state) => {
      state.stepsRemove.status = 'idle';
      state.stepsRemove.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStepsAsync.pending, (state) => {
        state.stepsLoad.status = 'loading';
      })
      .addCase(getAllStepsAsync.fulfilled, (state: any, action) => {
        state.stepsLoad.status = 'success';
        state.stepsLoad.data = action.payload;
      })
      .addCase(getAllStepsAsync.rejected, (state: any, action) => {
        state.stepsLoad.status = 'failed';
        state.stepsLoad.data = action.error;
      })
      .addCase(getSingleStepsAsync.pending, (state) => {
        state.stepsLoadSingle.status = 'loading';
      })
      .addCase(getSingleStepsAsync.fulfilled, (state: any, action) => {
        state.stepsLoadSingle.status = 'success';
        state.stepsLoadSingle.data = action.payload;
      })
      .addCase(getSingleStepsAsync.rejected, (state: any, action) => {
        state.stepsLoadSingle.status = 'failed';
        state.stepsLoadSingle.data = action.error;
      })
      .addCase(postStepsAsync.pending, (state) => {
        state.stepsAdd.status = 'loading';
      })
      .addCase(postStepsAsync.fulfilled, (state: any, action) => {
        state.stepsAdd.status = 'success';
        state.stepsAdd.data = action.payload;
      })
      .addCase(postStepsAsync.rejected, (state: any, action) => {
        state.stepsAdd.status = 'failed';
        state.stepsAdd.data = action.error;
      })
      .addCase(putStepsAsync.pending, (state) => {
        state.stepsMod.status = 'loading';
      })
      .addCase(putStepsAsync.fulfilled, (state: any, action) => {
        state.stepsMod.status = 'success';
        state.stepsMod.data = action.payload;
      })
      .addCase(putStepsAsync.rejected, (state: any, action) => {
        state.stepsMod.status = 'failed';
        state.stepsMod.data = action.error;
      })
      .addCase(deleteStepsAsync.pending, (state) => {
        state.stepsRemove.status = 'loading';
      })
      .addCase(deleteStepsAsync.fulfilled, (state: any, action) => {
        state.stepsRemove.status = 'success';
        state.stepsRemove.data = action.payload;
      })
      .addCase(deleteStepsAsync.rejected, (state: any, action) => {
        state.stepsRemove.status = 'failed';
        state.stepsRemove.data = action.error;
      });    
  },
});
export const {
  resetStepsLoad,
  resetStepsLoadSingle,
  resetStepsAdd,
  resetStepsMod,
  resetStepsRemove
} = stepsSlice.actions;
export const stepsLoadStatus = (state: RootState) =>
  state.steps.stepsLoad;
export const stepsLoadSingleStatus = (state: RootState) =>
  state.steps.stepsLoadSingle;
export const stepsAddStatus = (state: RootState) =>
  state.steps.stepsAdd;
export const stepsModStatus = (state: RootState) =>
  state.steps.stepsMod;
export const stepsRemoveStatus = (state: RootState) =>
  state.steps.stepsRemove;
export default stepsSlice.reducer;
  