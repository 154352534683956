import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { REACT_APP_API_URL } from '../../config';
import { _getConfig } from '../../utility/headers';

const initialState: any = {
  formPHOTO: {
    data: null,
    status: 'idle',
  },
};

//*-1- GET FormSchema  --  /schema/id/{PHOTO}
export const getFormPHOTOAsync = createAsyncThunk(
  'forms/formPHOTO',
  async () => {
    try {
      const response: any = await axios
        .get(`${REACT_APP_API_URL}/schema/data/id/PHOTO`, _getConfig(true))
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

export const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    resetFormPHOTO: (state) => {
      state.formPHOTO.status = 'idle';
      state.formPHOTO.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFormPHOTOAsync.pending, (state) => {
        state.formPHOTO.status = 'loading';
      })
      .addCase(getFormPHOTOAsync.fulfilled, (state: any, action) => {
        state.formPHOTO.status = 'success';
        state.formPHOTO.data = action.payload;
      })
      .addCase(getFormPHOTOAsync.rejected, (state: any, action) => {
        state.formPHOTO.status = 'failed';
        state.formPHOTO.data = action.error;
      });
  },
});
export const { resetFormPHOTO } = formsSlice.actions;
export const formPHOTOStatus = (state: RootState) => state.forms.formPHOTO;
export default formsSlice.reducer;
