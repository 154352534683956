import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { Fragment } from 'react';
import moment from 'moment';
import { actionNotificationLabelSwitch } from '../../utility/actions/actionLabels';

const InfoModalComponent = ({
  open,
  onClose,
  stoneSelected,
  dataSteps,
  stepSelected,
}: any) => {
  const handleCloseModal = () => {
    onClose();
  };

  const fwBold = { fontWeight: 'bold' };

  const infoProps: any = [];

  const specificTableColumns = dataSteps?.filter(
    (el: any) => el?.id === stepSelected
  )[0];

  const tableColumns = specificTableColumns.props?.tableColumn?.filter(
    (el: any) => el?.path !== 'actions'
  );

  const loopInfoValues = () => {
    for (let i = 0; i < tableColumns.length; i++) {
      const format: any = tableColumns?.[i]?.format;
      const type: any = tableColumns?.[i]?.type;
      const path: any = tableColumns?.[i]?.path;
      let defaultDate = true;
      let label: string = tableColumns?.[i]?.label;
      let value: any = _.at(stoneSelected, path)[0];

      if (path.startsWith('find:')) {
        const findIndex = path?.indexOf('find:');
        const startIndex = findIndex + 'find:'.length;
        const parenthesisIndex = path.indexOf('(', startIndex);
        const endIndex = path.indexOf(')', parenthesisIndex);
        const keyObject = path.substring(parenthesisIndex + 1, endIndex).trim();
        const arrayPath = path.substring(startIndex, parenthesisIndex).trim();
        const dataArrayFind: any = _.at(stoneSelected, arrayPath)[0];
        const targetObject = dataArrayFind?.filter(
          (el: any) => el?.key === keyObject
        )[0];
        value = targetObject?.value;
      }

      // se label è '' allora metti una label di default
      if (label === '') {
        label = `props${i}`;
      }

      // se value è undefined o null setta value di default
      if (value === undefined || value === null) {
        defaultDate = false;
        value = 'N/D';
      }

      // se tipo String e format è diverso da ''
      if (format !== '' && type === 'string' && value !== 'N/D') {
        const formattedString: string = format.replace('{val}', value);
        value = formattedString;
      }

      // se tipo Date
      if (type === 'date') {
        if (value !== 'N/D' && format !== '') {
          defaultDate = false;
          // se esiste il format e value viene settato nuova data
          const formattedDate: string = moment(value).format(format);
          value = formattedDate;
        }
        // return default pattern
        if (defaultDate) {
          value = moment(value).format('DD/MM/YY · HH:mm:ss');
        }
      }
      infoProps.push({ label: label, value: value });
    }
    return infoProps.map((el: any, i: number) => (
      <Fragment key={i}>
        <ListItem>
          <ListItemText
            primary={
              <div style={{ display: 'flex' }}>
                <span
                  style={{
                    ...fwBold,
                  }}
                >
                  {el?.label}:
                </span>{' '}
                <span style={{ marginLeft: '1em' }}>
                  {actionNotificationLabelSwitch(el?.value)}
                </span>
              </div>
            }
          />
        </ListItem>
        {i < infoProps?.length - 1 ? <Divider></Divider> : null}
      </Fragment>
    ));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ minWidth: '450px' }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item></Grid>
            <Grid item>
              <IconButton edge="end" onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box>
            <Divider textAlign="center">Info</Divider>
            <List>{loopInfoValues()}</List>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default InfoModalComponent;
