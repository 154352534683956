import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { REACT_APP_API_URL } from '../../config';
import axios from 'axios';
import { UserListState } from '../../model/userlistInterface';
import { _getConfig } from '../../utility/headers';

const initialState: UserListState = {
  userList: { data: null, status: 'idle' },
  newUser: { data: null, status: 'idle' },
  newPassword : { data: null, status: 'idle' },
  deleteUser : { data: null, status: 'idle' }
};

export const getUserListAsync = createAsyncThunk(
  'users/userList',
  async () => {
    try {
      const response: any = await axios
        .get(`${REACT_APP_API_URL}/user/all`, _getConfig(true, {}))
        .then((response: any) => {
          return response;
        });
      return response.data.sort((a:any, b:any) => a.id - b.id);
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const postNewUserAsync = createAsyncThunk(
  'users/newUser',
  async (e: any) => {
    const body = {
      name: e.labelNome.trim(),
      email: e.labelEmail.trim(),
      password: e.labelPassword,
      roles: e.labelRole.map((item :any)=> `ROLE_${item.toUpperCase()}`),
    };
    try {
      const response: any = await axios
        .post(`${REACT_APP_API_URL}/user/register`, body, _getConfig(true))
        .then((response: any) => {
          window.location.reload();
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const postNewPasswordAsync = createAsyncThunk(
  'users/newPassword',
  async (e: any) => {
    if (e.labelConfirmPassword === e.labelPassword) {
      const body = {
        newPassword: e.labelPassword,
      };
      try {
        const response: any = await axios
          .post(
            `${REACT_APP_API_URL}/user/reset-password`,
            body,
            _getConfig(true)
          )
          .then((response: any) => {
            window.location.reload();
            return response;
          });
        return response.data;
      } catch (e: any) {
        return Promise.reject(e.data ? e.data : e);
      }
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  'users/deleteUser',
  async (idUser: any) => {
    try {
      const response: any = await axios
        .delete(`${REACT_APP_API_URL}/user/delete/${idUser}`, _getConfig(true))
        .then((response: any) => {
          window.location.reload();
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUserList: (state) => {
      state.userList.status = 'idle';
      state.userList.data = null;
    },
    resetNewUser: (state) => {
      state.newUser.status = 'idle';
      state.newUser.data = null;
    },
    resetNewPassword: (state) => {
      state.newPassword.status = 'idle';
      state.newPassword.data = null;
    },
    resetDeleteUser: (state) => {
      state.deleteUser.status = 'idle';
      state.deleteUser.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserListAsync.pending, (state: any) => {
        state.userList.status = 'loading';
      })
      .addCase(getUserListAsync.fulfilled, (state: any, action) => {
        state.userList.status = 'success';
        state.userList.data = action.payload;
      })
      .addCase(getUserListAsync.rejected, (state: any, action) => {
        state.userList.status = 'failed';
        state.userList.data = action.error;
      })
      .addCase(postNewUserAsync.pending, (state: any) => {
        state.newUser.status = 'loading';
      })
      .addCase(postNewUserAsync.fulfilled, (state: any, action) => {
        state.newUser.status = 'success';
        state.newUser.data = action.payload;
      })
      .addCase(postNewUserAsync.rejected, (state: any, action) => {
        state.newUser.status = 'failed';
        state.newUser.data = action.error;
      })
      .addCase(postNewPasswordAsync.pending, (state: any) => {
        state.newPassword.status = 'loading';
      })
      .addCase(postNewPasswordAsync.fulfilled, (state: any, action) => {
        state.newPassword.status = 'success';
        state.newPassword.data = action.payload;
      })
      .addCase(postNewPasswordAsync.rejected, (state: any, action) => {
        state.newPassword.status = 'failed';
        state.newPassword.data = action.error;
      })
      .addCase(deleteUserAsync.pending, (state: any) => {
        state.deleteUser.status = 'loading';
      })
      .addCase(deleteUserAsync.fulfilled, (state: any, action) => {
        state.deleteUser.status = 'success';
        state.deleteUser.data = action.payload;
      })
      .addCase(deleteUserAsync.rejected, (state: any, action) => {
        state.deleteUser.status = 'failed';
        state.deleteUser.data = action.error;
      });
  },
});

export const {
  resetUserList,
  resetNewUser,
  resetNewPassword,
  resetDeleteUser,
} = usersSlice.actions;
export const userListState = (state: RootState) => state.users.userList;
export const newUserState = (state: RootState) => state.users.newUser;
export const newPasswordState = (state: RootState) => state.users.newPassword;
export const deleteUserState = (state: RootState) => state.users.deleteUser;
export default usersSlice.reducer;
