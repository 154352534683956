import { Button, Hidden } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getAuthorizedUserEmail } from '../../utility/token';
import { AccountCircle } from '@mui/icons-material';

const UserButtonMenuComponent = ({ open, handleClick, alpha, user }: any) => {
  return (
    <Button
      id="demo-customized-button"
      aria-controls={open ? 'demo-customized-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      variant="contained"
      disableElevation
      onClick={handleClick}
      endIcon={<KeyboardArrowDownIcon />}
      sx={{
        paddingRight: '2em',
        backgroundColor: '##1776d2',
        color: '##1776d2',
        '&:hover': {
          backgroundColor: alpha('##1776d2', 0.75),
        },
        marginRight: '30px',
      }}
    >
      <Hidden mdDown>
        <span style={{ paddingLeft: '1em' }}>
          {user?.name ?? getAuthorizedUserEmail()}
        </span>
      </Hidden>
      <Hidden mdUp><AccountCircle/></Hidden>
    </Button>
  );
};

export default UserButtonMenuComponent;
