const reduceOpacity = (hexColor: string, opacityPercent: number) => {
  if(!hexColor) return;
  // Remove '#' if it exists
  if (hexColor.charAt(0) === '#') {
    hexColor = hexColor.substr(1);
  }

  // Convert hex to RGB
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  // Calculate new RGBA values with reduced opacity
  const a = 1 - opacityPercent / 100;
  const newR = Math.round(r * a);
  const newG = Math.round(g * a);
  const newB = Math.round(b * a);

  // Convert new RGB values to hex
  const newHex = ((newR << 16) + (newG << 8) + newB).toString(16);

  // Pad with zeroes if necessary and return with '#' prefix
  return `#${newHex.padStart(6, '0')}`;
};

export { reduceOpacity };
