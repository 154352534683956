import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import MuiForm from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { RJSFSchema } from '@rjsf/utils';
import { useSelector } from 'react-redux';
import {
  dataIntoStoneStatus,
  postNewDataIntoStoneAsync,
  putDataIntoStoneAsync,
  putDataIntoStoneStatus,
  resetDataIntoStone,
  resetPutDataIntoStone,
} from '../../pages/GeneralView/stonesSlice';
import {
  formPHOTOStatus,
  getFormPHOTOAsync,
  resetFormPHOTO,
} from './ModalComponentSlice';
import CloseIcon from '@mui/icons-material/Close';
import CopyToClipboardComponent from '../CopyToClipboard/CopyToClipboardComponent';
import LoadingCircleComponent from '../Loading/LoadingCircleComponent';

const FormModalComponent = ({ open, onClose, formBody }: any) => {
  const dispatch = useAppDispatch();
  const [JSONDataModal, setJSONDataModal] = useState<RJSFSchema | any>({});
  const [schemajson, setSchemajson] = useState<object>({});
  const [uiSchema, setUiSchema] = useState<any>();
  const postDataIntoStoneAsyncStatus: any = useSelector(dataIntoStoneStatus);
  const putDataIntoStoneAsyncStatus: any = useSelector(putDataIntoStoneStatus);
  const getFormPHOTOAsyncStatus: any = useSelector(formPHOTOStatus);
  const handleCloseModal = () => {
    onClose();
  };

  // function validate(formData: any, errors: any) {
  //   console.log(formData);

  // for (const property in formData) {
  //   if (formData[property] === 0 || formData[property] === undefined) {
  //     errors?.[property]?.addError('Il valore non può essere 0 cm');
  //   }
  // }

  // if(formData?.height === 0 || formData?.height === undefined){
  //   errors?.height.addError('Il valore non può essere 0 cm');
  // }

  // return errors;
  // }

  function convertDataToFormData(data: any) {
    const formData: any = {};
    data?.forEach((item: any) => {
      formData[`${item.key}`] = item.value;
    });

    return formData;
  }

  const formData = convertDataToFormData(formBody?.data);
  useEffect(() => {
    dispatch(getFormPHOTOAsync());
    return () => {
      dispatch(resetFormPHOTO());
      dispatch(resetDataIntoStone());
      dispatch(resetPutDataIntoStone());
    };
  }, []);

  useEffect(() => {
    if (getFormPHOTOAsyncStatus.status === 'success') {
      setJSONDataModal(getFormPHOTOAsyncStatus.data);
    }
  }, [getFormPHOTOAsyncStatus]);

  useEffect(() => {
    if (JSONDataModal?.formModal?.length) {
      const [{ JSONSchema }, { UISchema }] = JSONDataModal.formModal;
      setSchemajson(JSONSchema);
      setUiSchema(UISchema);
    }
  }, [JSONDataModal]);

  useEffect(() => {
    if (postDataIntoStoneAsyncStatus.status === 'success') {
      handleCloseModal();
    }
  }, [postDataIntoStoneAsyncStatus]);

  useEffect(() => {
    if (putDataIntoStoneAsyncStatus.status === 'success') {
      handleCloseModal();
    }
  }, [putDataIntoStoneAsyncStatus]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ height: '20px', width: '98%' }}
      >
        <Grid item></Grid>
        <Grid item>
          <IconButton
            edge="end"
            onClick={handleCloseModal}
            style={{ padding: '24px' }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        {schemajson && uiSchema ? (
          <>
            <DialogTitle
              title={formBody?.code}
              style={{
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <CopyToClipboardComponent value={formBody.code} />
              {formBody?.code}
            </DialogTitle>
            <div>
              <MuiForm
                formContext={MuiForm}
                className={'formSchemaCSS'}
                onChange={() => {
                  if (uiSchema) {
                    const updatedUiSchema = { ...uiSchema };
                    updatedUiSchema['ui:submitButtonOptions'] = {
                      ...updatedUiSchema?.['ui:submitButtonOptions'],
                      props: {
                        disabled: false,
                        className: 'btn btn-info',
                      },
                    };
                    // setUiSchema(updatedUiSchema);
                  }
                }}
                schema={schemajson}
                uiSchema={uiSchema}
                formData={formData}
                validator={validator}
                // customValidate={validate}
                onSubmit={(e) => {
                  navigator.clipboard.writeText(formBody?.code);
                  const dataStone: any = {
                    stoneCode: formBody.code,
                    data: e.formData,
                    stepId: formBody?.stepId,
                  };
                  formBody.data.length > 0
                    ? dispatch(putDataIntoStoneAsync(dataStone))
                    : dispatch(postNewDataIntoStoneAsync(dataStone));
                }}
              />
            </div>
          </>
        ) : (
          <div>
            <LoadingCircleComponent size={100} />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FormModalComponent;
