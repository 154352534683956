import {
  addDays,
  startOfWeek,
  endOfWeek,
  subDays,
  startOfMonth,
  addMonths,
  endOfMonth,
} from 'date-fns';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'rsuite';
import { DateRangePickerState } from '../../model/dateRangePickerInterface';

const DateRangePickerComponent = ({
  setData,
  defaultValue,
  size,
  placeholder,
  disabled,
  style
}: DateRangePickerState) => {
  const [dateRange, setDateRange] = useState<[Date, Date] | any>(defaultValue);

  const handleDateChange = (date: [Date, Date] | null) => {
    if (date !== null) setDateRange([date[0], date[1]]);
  };

  const resetDate = () => {
    setDateRange(null);
  };

  useEffect(() => {
    setData(dateRange);
  }, [dateRange]);

  const locale: any = {
    sunday: 'Do',
    monday: 'Lu',
    tuesday: 'Ma',
    wednesday: 'Me',
    thursday: 'Gi',
    friday: 'Ve',
    saturday: 'Sa',
    ok: 'OK',
    time: 'Ora',
  };

  const predefinedRanges: any[] = [
    {
      label: 'Ieri',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'bottom',
      appearance: 'default',
    },
    {
      label: 'Oggi',
      value: [new Date(), new Date()],
      placement: 'bottom',
      appearance: 'default',
    },
    {
      label: 'Questa Settimana',
      closeOverlay: false,
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left',
    },
    {
      label: 'Ultimi 7 Giorni',
      closeOverlay: false,
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left',
    },
    {
      label: 'Ultimi 30 Giorni',
      closeOverlay: false,
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left',
    },
    {
      label: 'Questo Mese',
      closeOverlay: false,
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left',
    },
    {
      label: 'Mese Scorso',
      closeOverlay: false,
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: 'left',
    },
    {
      label: 'Quest\' Anno',
      closeOverlay: false,
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left',
    },
    {
      label: 'Anno Scorso',
      closeOverlay: false,
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: 'left',
    },
    {
      label: 'Dall\' Inizio',
      closeOverlay: false,
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'left',
    }
  ];

  return (
    <DateRangePicker
      showWeekNumbers
      character=' - '
      editable
      style={style}
      disabled={disabled}
      cleanable
      placeholder={placeholder}
      placement="auto"
      size={size}
      value={dateRange}
      locale={locale}
      onClean={resetDate}
      onChange={handleDateChange}
      ranges={predefinedRanges}
      format="dd/MM/yyyy"
    />
  );
};

export default DateRangePickerComponent;
