import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { REACT_APP_API_URL } from '../../config';
import { HistoryState } from '../../model/historyInterface';
import { _getConfig } from '../../utility/headers';

//*-1- GET ALL --  /history/type/${type}/all

const initialState: HistoryState = {
  historyLoad: {
    data: null,
    status: 'idle',
  },
};

//*-1- GET ALL
export const getAllHistoryAsync = createAsyncThunk(
  'history/getAllHistory',
  async (type: string) => {
    try {
      const response: any = await axios
        .get(`${REACT_APP_API_URL}/history/type/${type}/all`, _getConfig(true))
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    resetHistoryLoad: (state) => {
      state.historyLoad.status = 'idle';
      state.historyLoad.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllHistoryAsync.pending, (state) => {
        state.historyLoad.status = 'loading';
      })
      .addCase(getAllHistoryAsync.fulfilled, (state: any, action) => {
        state.historyLoad.status = 'success';
        state.historyLoad.data = action.payload;
      })
      .addCase(getAllHistoryAsync.rejected, (state: any, action) => {
        state.historyLoad.status = 'failed';
        state.historyLoad.data = action.error;
      });
  },
});

export const { resetHistoryLoad } = historySlice.actions;
export const historyLoadStatus = (state: RootState) => state.history.historyLoad;
export default historySlice.reducer;