import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const ErrorPage = () => {

  const navigate = useNavigate();

  const returnHome = () => {
    navigate('/');
  };

  return (
    <div
      id="error-page"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <h1>Oops!</h1>
      <br />
      <p>Sorry, an unexpected error has occurred.</p>
      <Button 
        onClick={returnHome}
        style={{ 
          marginTop: '10px',
          width: '20%', 
          textDecoration: 'underline', 
          fontWeight: 600, 
          letterSpacing: '1.2pt' 
        }}
        variant="text"
        color="primary"
      ><span>GO HOME</span></Button>
    </div>
  );
};

export default ErrorPage;
