import _ from 'lodash';

const stepsProfiledVisible = (steps: any) => {
  const stepsVisible = [];
  for (const step in steps) {
    stepsVisible.push(steps?.[step]?.id);
  }
  return stepsVisible;
};

const stepsProfiledArrayByPath = (steps: any, path: string) => {
  const stepsVisible = [];
  const stepsByOrderNumber = [...steps];
  const enabledArray = stepsByOrderNumber?.sort(
    (a: any, b: any) => a?.orderNum - b?.orderNum
  );
  for (const step in enabledArray) {
    stepsVisible.push(_.at(enabledArray?.[step], path));
  }
  return stepsVisible;
};

export { stepsProfiledVisible, stepsProfiledArrayByPath };
