import { Box, Grid, Paper, Typography } from '@mui/material';
import Layout from '../Layout/Layout';
import {
  getAllStonesAsync,
  resetStonesLoad,
  stonesLoadStatus,
} from '../GeneralView/stonesSlice';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { StonesState } from '../../model/stonesInterface';
import {
  getAllHistoryAsync,
  historyLoadStatus,
  resetHistoryLoad,
} from './historySlice';
import { HistoryState } from '../../model/historyInterface';
import { TYPE } from '../../config';
import { StepsState } from '../../model/stepsInterface';
import { stepsLoadStatus } from '../GeneralView/stepsSlice';
import LineChart from './LineChart';
import PolarArea from './PolarAreaChart';
import PieChart from './PieChart';
import { Timeline } from '@mui/icons-material';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const [dataStones, setDataStones] = useState<any>([]);
  const [dataSteps, setDataSteps] = useState<any>([]);
  const [dataHistory, setDataHistory] = useState<any>([]);

  //*-- STONES - statusSelector
  const getAllStonesStatusSelector: StonesState['stonesLoad'] =
    useSelector(stonesLoadStatus);

  //*-- STEPS - statusSelector
  const getAllStepsStatusSelector: StepsState['stepsLoad'] =
    useSelector(stepsLoadStatus);

  //*-- HISTORY - statusSelector
  const getAllHistoryStatusSelector: HistoryState['historyLoad'] =
    useSelector(historyLoadStatus);

  //------ USEFFECTS -------------------------------

  useEffect(() => {
    dispatch(getAllStonesAsync(`${TYPE}`));
    dispatch(getAllHistoryAsync(`${TYPE}`));
    return () => {
      dispatch(resetStonesLoad());
      dispatch(resetHistoryLoad());
    };
  }, []);

  useEffect(() => {
    if (getAllStonesStatusSelector.status === 'success') {
      setDataStones(getAllStonesStatusSelector.data);
    }
  }, [getAllStonesStatusSelector]);

  useEffect(() => {
    if (getAllStepsStatusSelector.status === 'success') {
      setDataSteps(getAllStepsStatusSelector.data);
    }
  }, [getAllStepsStatusSelector]);

  useEffect(() => {
    if (getAllHistoryStatusSelector.status === 'success') {
      setDataHistory(getAllHistoryStatusSelector.data);
    }
  }, [getAllHistoryStatusSelector]);

  return (
    <Layout>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={12} xl={9}>
          <Box sx={{ p: 1, textAlign: 'center' }}>
            <Paper
              elevation={3}
              sx={{
                borderRadius: '15px',
                padding: '15px',
                width: '100%',
                overflow: 'hidden',
                textAlign: 'center',
                height: '814px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Timeline
                  sx={{
                    fontSize: '40px',
                    fill: 'rgb(234, 169, 0)',
                    marginRight: '15px',
                  }}
                />
                <Typography variant="h5" component="h2">
                  Reperti processati nel tempo
                </Typography>
              </div>
              {/* LINE CHART */}
              <LineChart
                stones={dataStones}
                steps={dataSteps}
                history={dataHistory}
              />
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={true} md={12} xl={3}>
          <Box sx={{ p: 1, textAlign: 'center' }}>
            <Paper
              elevation={3}
              sx={{
                borderRadius: '15px',
                padding: '15px',
                width: '100%',
                height: '400px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* PIE CHART */}
              <PieChart stones={dataStones} />
            </Paper>
          </Box>
          <Box sx={{ p: 1, textAlign: 'center' }}>
            <Paper
              elevation={3}
              sx={{
                borderRadius: '15px',
                padding: '15px',
                width: '100%',
                height: '400px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* POLAR AREA CHART */}
              <PolarArea stones={dataStones} steps={dataSteps} />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Dashboard;
