import { socketMessageParsed } from './messageParsed';

const notificationCheck = (msg: any) => {
  const msgParsed = socketMessageParsed(msg);
  if (localStorage.getItem('notifications')) {
    const notificationStorage = localStorage.getItem('notifications');
    const notificationStorageParsed = notificationStorage
      ? JSON.parse(notificationStorage)
      : null;
    localStorage.setItem(
      'notifications',
      JSON.stringify([...notificationStorageParsed, msgParsed])
    );
  } else {
    localStorage.setItem('notifications', JSON.stringify([msgParsed]));
  }
};

export { notificationCheck };
