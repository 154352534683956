import { getToken } from '../utility/token';

export const _getConfig = (
  authorization = false,
  query = {},
  type = 'application/json'
) => {
  const headers: any = {
    'Content-Type': type,
  };
  if (authorization) {
    headers['Authorization'] = `Bearer ${getToken()}`;
  }
  return { headers, timeout: 30000, params: query };
};
