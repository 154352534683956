import { Grid, Skeleton } from '@mui/material';

const LoadingSkeletonComponent = ({ skeletons }: any) => {
  const grids: any = [];

  for (let i = 0; i < skeletons; i++) {
    grids.push(
      <Grid item xs={12} md={6} xl={4} key={i}>
        <Skeleton animation="wave" />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {grids}
    </Grid>
  );
};

export default LoadingSkeletonComponent;
