import { Grid, Button, Paper, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import TableComponent from '../../components/Table/TableComponent';
import Layout from '../Layout/Layout';
import { userListState, getUserListAsync } from './usersSlice';
import AddUserModalComponent from '../../components/Modal/AddUserModalComponent';
//import SearchFilterComponent from '../../components/Search-Filter/SearchFilterComponent';
import { Edit, Delete, PersonAddAlt } from '@mui/icons-material';
import DeleteUserModalComponent from '../../components/Modal/DeleteUserModalComponent';
import ModifyUserModalComponent from '../../components/Modal/ModifyUserModalComponent';
import { getAuthorizedUserEmail, isAdmin } from '../../utility/token';

const Users = () => {
  const dispatch = useAppDispatch();
  const userListStatusSelector = useSelector(userListState);
  const [usersData, setUsersData] = useState<any>([]);
  const [userListSchema, setUserListSchema] = useState<any>({
    properties: {},
  });
  const [rowUser, setRowUser] = useState<any>();
  const [openModifyUserModalVisible, setOpenModifyUserModalVisible] =
    useState(Boolean);
  const [openDeleteUserModalVisible, setOpenDeleteUserModalVisible] =
    useState(Boolean);
  const [openUserModalVisible, setOpenUserModalVisible] = useState(Boolean);

  const columns: any = [
    { name: 'ID', label: 'id' },
    { name: 'Nome', label: 'name' },
    { name: 'Email', label: 'email' },
    { name: 'Password', label: 'password' },
    { name: 'Ruoli', label: 'roles' },
    { name: 'Azioni', label: 'actions' },
  ];

  const handleCloseModifyUserModal = () => {
    setOpenModifyUserModalVisible(false);
  };

  const handleCloseDeleteUserModal = () => {
    setOpenDeleteUserModalVisible(false);
  };

  const handleCloseUserModal = () => {
    setOpenUserModalVisible(false);
  };

  const getUserList = () => {
    dispatch(getUserListAsync());
  };

  const addUserButton = () => {
    return isAdmin() ? (
      <div
        style={{
          padding: '15px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          startIcon={<PersonAddAlt />}
          onClick={() => setOpenUserModalVisible(true)}
        >
          <span>Aggiungi Utente</span>
        </Button>
      </div>
    ) : null;
  };

  const titleHover: any = (userList: any, columnKey: any) => {
    const cellValue: any = userList?.[columnKey?.label];
    return cellValue;
  };

  const renderCell = (userList: any, columnKey: any) => {
    const cellValue: any = userList?.[columnKey?.label];

    switch (columnKey?.label) {
      case 'actions':
        return userList?.email === getAuthorizedUserEmail() ? (
          <Tooltip title="Modifica" arrow>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                setOpenModifyUserModalVisible(true);
                //deleteNewUser(row.id);
                setRowUser(userList);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Elimina" arrow>
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => {
                setOpenDeleteUserModalVisible(true);
                //setUserId(row.id);
                setRowUser(userList);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        );

      default:
        return cellValue ?? 'N/D';
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    if (userListStatusSelector.status === 'success') {
      setUsersData(
        userListStatusSelector.data.map((item: any) => {
          const roles = item.roles.map((item: any, index: number) => {
            let rolestring = '';
            rolestring =
              index > 0
                ? `${rolestring} ${item.split('_')[1]}`
                : `${item.split('_')[1]}`;
            return rolestring;
          });
          return {
            ...item,
            actions: 'del',
            roles: roles.toString(),
            password: '****',
          };
        })
      );
    }
  }, [userListStatusSelector.status]);

  useEffect(() => {
    if (usersData) {
      for (const property in usersData?.[0]) {
        setUserListSchema((prevState: any) => ({
          ...prevState,
          properties: {
            ...prevState.properties,
            [property]: typeof usersData?.[0]?.[property],
          },
        }));
      }
    }
  }, [usersData]);

  return (
    <>
      <Layout>
        <Grid
          container
          spacing={2}
        >
          <Paper sx={{ width: '100%' }}>
            <Grid item xs={12}>
              {addUserButton()}
              <div style={{ padding: '10px' }}>
                <TableComponent
                  className={'TableListUsers'}
                  data={usersData}
                  schema={userListSchema}
                  styleHieght={'auto'}
                  searchFilter={true}
                  selectFilter={false}
                  titleHover={titleHover}
                  columns={columns}
                  renderCell={renderCell}
                />
              </div>
            </Grid>
            {openUserModalVisible && (
              <AddUserModalComponent
                open={true}
                onClose={() => handleCloseUserModal()}
              />
            )}
            {/*MODIFY USER MODAL*/}
            {openModifyUserModalVisible && (
              <ModifyUserModalComponent
                open={openModifyUserModalVisible}
                onClose={() => handleCloseModifyUserModal()}
                rowUser={rowUser}
              />
            )}
            {/*DELETE MODAL*/}
            {openDeleteUserModalVisible && (
              <DeleteUserModalComponent
                open={openDeleteUserModalVisible}
                onClose={() => handleCloseDeleteUserModal()}
                rowUser={rowUser}
              />
            )}
          </Paper>
        </Grid>
      </Layout>
    </>
  );
};

export default Users;
