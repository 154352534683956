import { ContentCopy } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastDefaultProps } from '../../utility/toastBodyProps';

const CopyToClipboardComponent = ({ value }: { value: any }) => {
  const handleClick = (value: string) => {
    navigator.clipboard.writeText(value);
    toast.info(`Codice: ${value} copiato!`, toastDefaultProps);
  };

  return (
    <>
      <IconButton
        onClick={() => handleClick(value)}
        style={{ backgroundColor: 'unset' }}
      >
        <Tooltip title="Copia" placement="left" arrow>
          <ContentCopy color="primary" fontSize="small" />
        </Tooltip>
      </IconButton>
    </>
  );
};

export default CopyToClipboardComponent;
