import { Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiForm from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { RJSFSchema } from '@rjsf/utils';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import {
  postStonesAsync,
  resetStonesAdd,
  stonesAddStatus,
} from '../../pages/GeneralView/stonesSlice';
import { useSelector } from 'react-redux';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { StepsObject } from '../../model/stepsInterface';
import LoadingCircleComponent from '../Loading/LoadingCircleComponent';

const AddNewStoneModalComponent = ({ open, onClose, stepForm }: any) => {
  const dispatch = useAppDispatch();
  const [JSONDataModal, setJSONDataModal] = useState<RJSFSchema | any>({});
  const [schemajson, setSchemajson] = useState<RJSFSchema>();
  const [uiSchema, setUiSchema] = useState<RJSFSchema>();
  const [getError, setGetError] = useState(false);
  const postStonesAsyncStatusSelector = useSelector(stonesAddStatus);

  const handleCloseModal = () => {
    onClose();
  };

  const getJSONUserModal = () => {
    setJSONDataModal(AddStoneSchema);
  };

  const pushStepThatISee = () => {
    if (!stepForm) {
      return;
    }
    const StepLabelsAuthorized: string[] = [];
    const iterStepForm: Array<StepsObject> = [...stepForm];
    const stepFormByOrderNum: Array<StepsObject> = iterStepForm?.sort(
      (a: any, b: any) => a?.orderNum - b?.orderNum
    );
    for (const step in stepFormByOrderNum) {
      StepLabelsAuthorized?.push(stepFormByOrderNum?.[step]?.label);
    }
    return StepLabelsAuthorized;
  };

  const AddStoneSchema = [
    {
      JSONSchema: {
        title: 'Aggiunta Reperto',
        type: 'object',
        required: ['Inventario', 'Collocazione', 'Oggetto', 'Step'],
        properties: {
          Inventario: {
            type: 'string',
            title: 'Inventario',
          },
          Collocazione: {
            type: 'string',
            title: 'Collocazione',
          },
          Oggetto: {
            type: 'string',
            title: 'Oggetto',
          },
          Step: {
            type: 'string',
            title: 'Step',
            default: 'Da processare',
            enum: pushStepThatISee(),
          },
          Note: {
            type: 'string',
            title: 'Note',
          },
        },
      },
      UISchema: {
        Note: {
          'ui:widget': 'textarea',
        },
        'ui:submitButtonOptions': {
          props: {
            disabled: true,
            className: 'btn btn-info',
          },
          norender: false,
          submitText: 'Submit',
        },
      },
    },
  ];

  useEffect(() => {
    getJSONUserModal();
    return () => {
      dispatch(resetStonesAdd());
    };
  }, []);

  useEffect(() => {
    if (JSONDataModal) {
      setSchemajson(JSONDataModal[0]?.JSONSchema);
      setUiSchema(JSONDataModal[0]?.UISchema);
    }
  }, [JSONDataModal]);

  useEffect(() => {
    if (postStonesAsyncStatusSelector.status === 'success') {
      onClose();
    }
    if (postStonesAsyncStatusSelector.status === 'failed') {
      setGetError(true);
    }
  }, [postStonesAsyncStatusSelector]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleCloseModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ height: '20px', width: '98%', overflow: 'hidden' }}
        >
          <Grid item></Grid>
          <Grid item>
            <IconButton
              edge="end"
              onClick={() => handleCloseModal()}
              style={{ padding: '24px' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          {schemajson && uiSchema ? (
            <div>
              {getError ? (
                <div
                  style={{
                    color: '#d32f2f',
                    fontWeight: '400',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ErrorOutlineIcon />
                  <p>Esiste già un reperto con questo codice</p>
                </div>
              ) : null}
              <MuiForm
                formContext={MuiForm}
                className={'formSchemaCSS'}
                onChange={() => {
                  if (uiSchema) {
                    Object.assign(uiSchema['ui:submitButtonOptions']?.props, {
                      disabled: false,
                      className: 'btn btn-info',
                    });
                  }
                }}
                schema={schemajson}
                uiSchema={uiSchema}
                validator={validator}
                onSubmit={(e) => {
                  const props: any = {
                    Collocazione: e.formData.Collocazione,
                    Oggetto: e.formData.Oggetto,
                    Note: e.formData.Note,
                  };
                  // e.formData.labelProps.forEach((item: any, index: number) => {
                  //   props[`${index}`] = item;
                  // });
                  const stepID = stepForm.find(
                    (item: any) => item.label === e.formData.Step
                  ).id;
                  const body = {
                    code: e.formData.Inventario,
                    stepId: stepID,
                    props: props,
                  };
                  dispatch(postStonesAsync(body));
                }}
              />
            </div>
          ) : (
            <div>
              <LoadingCircleComponent size={100} />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddNewStoneModalComponent;
