import { Typography } from '@mui/material';
import { Step } from '../../model/stepsInterface';
import SvgComponent from '../SVG/SvgComponent';

const TitleTableComponent = ({stepObject}: Step) => {
  const svgProps: object = stepObject?.props?.svgProps;
  const stepLabel: string = stepObject?.label;
  return (
    <Typography
      variant="h6"
      sx={{
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
        fontWeight: '400',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'flex-end',
        marginRight: '15px',
      }}
    >
      <SvgComponent svgProps={svgProps} />
      <span style={{marginLeft: '5px'}}>{stepLabel}</span>
    </Typography>
  );
};

export default TitleTableComponent;
