const stonesStatusGroup = (stones: any) => {
  //*-- NOT PROCESSED
  const notProcessedStones: any[] = stones?.filter(
    (el: any) => el?.steps?.[0]?.stepId === 'FULL_LIST'
  );

  const notProcessedStonesCounter: number = notProcessedStones?.length;

  //*-- WORK IN PROGRESS
  const workInProgressStones: any[] = stones?.filter(
    (el: any) =>
      el?.steps?.[0]?.stepId !== 'FULL_LIST' &&
      el?.steps?.[0]?.stepId !== 'DONE'
  );

  const workInProgressStonesCounter: number = workInProgressStones?.length;

  //*-- PROCESSED
  const processedStones: any[] = stones?.filter(
    (el: any) => el?.steps?.[0]?.stepId === 'DONE'
  );

  const processedStonesCounter: number = processedStones?.length;

  //*-- TOTAL
  const totalStonesCounter: number = stones?.length;

  return {
    notProcessed: {
      data: notProcessedStones,
      counter: notProcessedStonesCounter,
    },
    workInProgress: {
      data: workInProgressStones,
      counter: workInProgressStonesCounter,
    },
    processed: {
      data: processedStones,
      counter: processedStonesCounter,
    },
    total: totalStonesCounter,
  };
};

export { stonesStatusGroup };
