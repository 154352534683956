import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tokenState } from '../../utility/tokenSlice';
import GeneralView from '../GeneralView/GeneralView';

const IsAuth = () => {
  const token: any = useSelector(tokenState);

  return <>{token ? <GeneralView /> : <Navigate to="/login" replace />}</>;
};

export default IsAuth;
