import PieChartComponent from '../../components/charts/PieChartComponent';
import { stonesStatusGroup } from '../../utility/stonesFilters/stonesFilterByStatus';

const PieChart = ({ stones }: any) => {
  //*-- PIE CHART DATA

  const pieLegendPosition = 'top';
  const pieChartTitle = 'Status Reperti';
  const pieLabels: string[] = ['Totali', 'In Lavorazione', 'Completati'];
  const pieBgColor: string[] = ['#238cd8', '#ff9800', '#4caf50'];

  const dataArrayPie: number[] = [
    stonesStatusGroup(stones)?.total,
    stonesStatusGroup(stones)?.workInProgress?.counter,
    stonesStatusGroup(stones)?.processed?.counter,
  ];
  return (
    <PieChartComponent
      dataArray={dataArrayPie}
      dataLabel={pieLabels}
      dataBgColor={pieBgColor}
      legendPosition={pieLegendPosition}
      chartTitle={pieChartTitle}
    />
  );
};

export default PieChart;
