import { Input } from '@mui/material';
import { SearchFilterState } from '../../model/searchFilterInterface';

const SearchFilterComponent = ({
  setData,
  size,
  placeholder,
  disabled,
  style,
}: SearchFilterState) => {

  const onChange = (e: any) => {
    setData(e.target.value);
  };

  return (
    <Input
      placeholder={placeholder}
      style={style}
      onChange={onChange}
      disabled={disabled}
      size={size}
    />
  );
};

export default SearchFilterComponent;
