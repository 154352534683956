import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { getRefreshToken, getToken } from './token';



const initialState = {
  token: getToken(),
  refreshToken: getRefreshToken()
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action) => {
      localStorage.setItem('jwtToken', action.payload);
      state.token = action.payload;
    },
    setRefreshToken: (state, action) => {
      localStorage.setItem('refreshToken', action.payload);
      state.refreshToken = action.payload;
    },
    removeToken: (state) => {
      localStorage.removeItem('jwtToken');
      state.token = null;
    },
    removeRefreshToken: (state) => {
      localStorage.removeItem('refreshToken');
      state.refreshToken = null;
    },
  },
});

export const { setToken, setRefreshToken, removeToken, removeRefreshToken } =
  tokenSlice.actions;
export const tokenState = (state: RootState) => state.token.token;
export const refreshTokenState = (state: RootState) => state.token.refreshToken;
export default tokenSlice.reducer;
