import {
  Box,
  CircularProgress,
  CircularProgressProps,
  circularProgressClasses,
} from '@mui/material';

const LoadingCircleComponent = ({ size }: any) => {

  const CustomCircularProgress = (props: CircularProgressProps) => {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={size ?? 40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) =>
              theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={size ?? 40}
          thickness={4}
          {...props}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomCircularProgress />
    </Box>
  );
};

export default LoadingCircleComponent;
