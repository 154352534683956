import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TYPE } from '../../config';

const TitleNavbarComponent = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Typography
        onClick={() => {
          navigate('/home');
        }}
        variant="h4"
        sx={{
          flexGrow: 1,
          fontWeight: 'bold',
          marginLeft: '10px',
          cursor: 'pointer',
          color: 'black',
          letterSpacing: '0.2em'
        }}
      >
        {TYPE?.toUpperCase()}
      </Typography>
    </div>
  );
};

export default TitleNavbarComponent;
