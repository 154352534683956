import { toast } from 'react-toastify';
import { getAuthorizedUserEmail } from '../token';
import { toastSocket } from '../toastBodyProps';
import { socketMessageParsed } from './messageParsed';
import { stepsProfiledVisible } from './stepsProfiled';
import MessageHTML from './MessageHTML';

const subscribeClientHandle: any = (msg: any, steps: any) => {
  const mex: any = socketMessageParsed(msg);
  const stepsVisible: any = stepsProfiledVisible(steps);
  const prevStep = mex?.stepId;
  const newStep = mex?.newStep;
  const checkSteps = [prevStep, newStep];
  if (
    checkSteps.some((el: string) => stepsVisible.includes(el)) 
    && mex?.username !== getAuthorizedUserEmail()
  ) {
    setTimeout(() => {
      toast.info(<MessageHTML item={mex} steps={steps} />, toastSocket);
    }, 500);
    return true;
  }
};

export { subscribeClientHandle };
