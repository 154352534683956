import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import { ChartPolarAreaState } from '../../model/chartsInterface';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const PolarAreaChartComponent = ({
  dataArray,
  dataLabel,
  dataBgColor,
  legendPosition,
  chartTitle,
}: ChartPolarAreaState) => {
  const options: ChartOptions<'polarArea'> = {
    responsive: true,
    plugins: {
      legend: {
        position: legendPosition,
      },
      title: {
        display: chartTitle ? true : false,
        text: chartTitle,
      },
    },
  };

  const data = {
    labels: dataLabel,
    datasets: [
      {
        label: 'tot',
        data: dataArray,
        backgroundColor: dataBgColor,
        borderWidth: 1,
      },
    ],
  };

  return dataArray && dataArray.length > 0 ? (
    <PolarArea options={options} data={data} />
  ) : (
    <div>Nessun dato disponibile</div>
  );
};

export default PolarAreaChartComponent;
