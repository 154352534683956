import { ArrowForward, Done, Replay } from '@mui/icons-material';

const ActionIcons: any = ({action}: any) => {
  const iconSwitch = (action: string) => {
    const style = { fontSize: '1.5em' };
    switch (action) {
      case 'TAKE_CHARGE':
        return <ArrowForward sx={{ ...style, color: '#0d47a1' }} />;
      case 'REJECT':
        return <Replay sx={{ ...style, color: '#ff9800' }} />;
      case 'FINISH':
        return <Done sx={{ ...style, color: '#4caf50' }} />;
      default:
        break;
    }
  };
  return iconSwitch(action);
};

export default ActionIcons;
