import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import tokenReducer from '../utility/tokenSlice';
import loginReducer from '../pages/Login/loginSlice';
import typeReducer from '../components/Navbar/typeSlice';
import usersReducer from '../pages/Users/usersSlice';
import stonesReducer from '../pages/GeneralView/stonesSlice';
import stepsReducer from '../pages/GeneralView/stepsSlice';
import formsReducer from '../components/Modal/ModalComponentSlice';
import notificationReducer from '../utility/socket/notificationSlice';
import historyReducer from '../pages/Dashboard/historySlice';

export const store = configureStore({
  reducer: {
    token: tokenReducer,
    login: loginReducer,
    type: typeReducer,
    users: usersReducer,
    stones: stonesReducer,
    steps: stepsReducer,
    forms: formsReducer,
    notification: notificationReducer,
    history: historyReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
