import jwtDecode from 'jwt-decode';

//*-- TOKEN: string
export const getToken: any = () => {
  if (!localStorage.getItem('jwtToken')) {
    return;
  }

  const token: any = localStorage.getItem('jwtToken');
  const decodedToken: any = token ? jwtDecode(token) : null;
  if (token) {
    if (decodedToken?.exp * 1000 < Date.now()) {
      localStorage.removeItem('jwtToken');
      window.location.pathname = '/login';
      localStorage.removeItem('USER');
    } else {
      return token;
    }
  }
};

//*-- REFRESH TOKEN: string
export const getRefreshToken: any = () => {
  return localStorage.getItem('refreshToken');
};

export const getAuthorizedRoles: any = () => {
  if (!localStorage.getItem('jwtToken')) return;
  const authorizedRoles: any[] = [];
  const tokenDecoded: any = jwtDecode(getToken());
  for (const i in tokenDecoded?.realm_access?.roles) {
    authorizedRoles.push(tokenDecoded?.realm_access?.roles[i]);
  }
  return authorizedRoles;
};

//*-- PREFERRED USERNAME: string
export const getAuthorizedPreferredUsername: any = () => {
  if (!localStorage.getItem('jwtToken')) return;
  const tokenDecoded: any = jwtDecode(getToken());
  const authorizedPreferredUsername: string = tokenDecoded?.preferred_username;
  return authorizedPreferredUsername;
};

//*-- EMAIL: string
export const getAuthorizedUserEmail: any = () => {
  if (!localStorage.getItem('jwtToken')) return;
  const tokenDecoded: any = jwtDecode(getToken());
  const authorizedUserEmail: string = tokenDecoded?.email;
  return authorizedUserEmail;
};

//*-- USER IS ADMIN: boolean
export const isAdmin = () => {
  if (getAuthorizedRoles()?.includes('ROLE_ADMIN')) {
    return true;
  } else false;
};

//*-- USER IS AUTHORIZED FOR CREATE: boolean
export const isCreator = (allowedCreatorRoles: any[]) => {
  const authRoles = getAuthorizedRoles();
  if (allowedCreatorRoles?.some((role: string) => authRoles.includes(role))) {
    return true;
  } else {
    return false;
  }
};
