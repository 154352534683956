import { Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import MuiForm from '@rjsf/mui';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import {
  postNewPasswordAsync,
  resetNewPassword,
} from '../../pages/Users/usersSlice';
import LoadingCircleComponent from '../Loading/LoadingCircleComponent';

const ModifyUserModalComponent = ({ open, onClose, rowUser }: any) => {
  const dispatch = useAppDispatch();
  const [JSONDataModal, setJSONDataModal] = useState<RJSFSchema | any>({});
  const [formData, setFormData] = useState<RJSFSchema | any>({});
  const [schemajson, setSchemajson] = useState<RJSFSchema>();
  const [uiSchema, setUiSchema] = useState<RJSFSchema>();
  const handleCloseModal = () => {
    onClose();
  };

  function validate(formData: any, errors: any) {
    if (formData.labelPassword !== formData.labelConfirmPassword) {
      errors.labelPassword.addError('Le password non corrispondono');
      errors.labelConfirmPassword.addError('Le password non corrispondono');
    }
    return errors;
  }

  const getJSONModifyUserModal = () => {
    setJSONDataModal(modifyUserSchema);
  };

  const modifyUserSchema = [
    {
      JSONSchema: {
        title: 'Modifica Utente',
        type: 'object',
        required: ['labelPassword', 'labelConfirmPassword'],
        properties: {
          labelNome: {
            type: 'string',
            title: 'Nome dell\'utente',
          },
          labelEmail: {
            type: 'string',
            title: 'Email',
          },
          labelPassword: {
            type: 'string',
            title: 'Password',
          },
          labelConfirmPassword: {
            type: 'string',
            title: ' Conferma Password',
          },
          labelRole: {
            type: 'string',
            title: 'Ruolo',
          },
        },
      },
      UISchema: {
        labelEmail: {
          'ui:disabled': true,
        },
        labelNome: {
          'ui:disabled': true,
        },
        labelRole: {
          'ui:disabled': true,
        },
        labelPassword: {
          'ui:widget': 'password',
          'ui:error': 'Passwords don\'t match',
        },
        labelConfirmPassword: {
          'ui:widget': 'password',
          'ui:error': 'Passwords don\'t match',
        },
        'ui:submitButtonOptions': {
          props: {
            disabled: true,
            className: 'btn btn-info',
          },
          norender: false,
          submitText: 'Submit',
        },
      },
    },
  ];
  useEffect(() => {
    getJSONModifyUserModal();
    setFormData({
      labelNome: rowUser.name,
      labelEmail: rowUser.email,
      labelPassword: null,
      labelConfirmPassword: null,
      labelRole: rowUser.roles,
    });
    return () => {
      dispatch(resetNewPassword());
    };
  }, []);

  useEffect(() => {
    setSchemajson(JSONDataModal[0]?.JSONSchema);
    setUiSchema(JSONDataModal[0]?.UISchema);
  }, [JSONDataModal]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ height: '20px', width: '98%' }}
      >
        <Grid item></Grid>
        <Grid item>
          <IconButton
            edge="end"
            onClick={handleCloseModal}
            style={{ padding: '24px' }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        {schemajson && uiSchema ? (
          <div>
            <MuiForm
              formContext={MuiForm}
              className={'formSchemaCSS'}
              onChange={() => {
                if (uiSchema) {
                  Object.assign(uiSchema['ui:submitButtonOptions']?.props, {
                    disabled: false,
                    className: 'btn btn-info',
                  });
                }
              }}
              schema={schemajson}
              uiSchema={uiSchema}
              validator={validator}
              formData={formData}
              customValidate={validate}
              onSubmit={(e) => {
                dispatch(postNewPasswordAsync(e.formData));
                handleCloseModal();
              }}
            />
          </div>
        ) : (
          <div>
            <LoadingCircleComponent size={100} />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModifyUserModalComponent;
