import { createSlice } from '@reduxjs/toolkit';

const notificationStored = () => {
  if(localStorage.getItem('notifications')){
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return JSON.parse(localStorage.getItem('notifications')!);
  } else {
    return [];
  }
};  

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notificationList: notificationStored(),
  },
  reducers: {
    setNotification: (state: any, action: any) => {
      state.notificationList.push(action.payload);
    },
    resetNotification: (state: any) => {
      state.notificationList = [];
    },
  },
});

export const { setNotification, resetNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
