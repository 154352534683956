import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useAppDispatch } from '../../app/hooks';
import { useSelector } from 'react-redux';
import { loginAsync, loginState, resetLogin } from './loginSlice';
import {
  removeToken,
  // setRefreshToken,
  setToken,
  tokenState,
} from '../../utility/tokenSlice';
import { useNavigate } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { REACT_APP_WS_URL, TYPE } from '../../config';
import { disconnectWebSocket, stompSocket } from '../../utility/socket/socket';
import { getAllStonesAsync } from '../GeneralView/stonesSlice';
import { getAllStepsAsync } from '../GeneralView/stepsSlice';
import {subscribeClientHandle} from '../../utility/socket/subscribeClient';
import { resetNotification, setNotification } from '../../utility/socket/notificationSlice';
import { socketMessageParsed } from '../../utility/socket/messageParsed';
import { notificationCheck } from '../../utility/socket/notificationCheckLocalStorage';

interface LoginForm {
  username: string;
  password: string;
}

const Login = () => {
  const token: any = useSelector(tokenState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(false);
  const loginStatusSelector = useSelector(loginState);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const socketNotify: any = (msg: any) => {
    dispatch(getAllStonesAsync(`${TYPE}`));
    dispatch(setNotification(socketMessageParsed(msg)));
    notificationCheck(msg);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const userObj: LoginForm =
      { username: username, password: password } ?? null;
    dispatch(loginAsync(userObj));
  };

  const loginErrorCheck = () => {
    if (loginError) {
      setLoginError(false);
    }
  };

  const redirectApp = () => {
    navigate('/home');
  };

  const handleLogout = () => {
    dispatch(removeToken());
    dispatch(resetLogin());
    dispatch(resetNotification());
    localStorage.removeItem('USER');
    localStorage.removeItem('notifications');
    disconnectWebSocket(REACT_APP_WS_URL);
    navigate('/login');
  };

  useEffect(() => {
    return () => {
      dispatch(resetLogin());
    };
  }, []);

  //*-- STOMP JS SOCKET after LOGIN
  useEffect(() => {
    if (loginStatusSelector.status === 'success') {
      let steps: any;
      setLoginError(false);
      dispatch(setToken(loginStatusSelector.data.token));
      dispatch(getAllStepsAsync(`${TYPE}`))
        .unwrap()
        .then((response: any) => {
          steps = response;
        })
        .catch(() => {
          // console.log(err);
        });
      stompSocket('/queue', loginStatusSelector?.data?.token, (msg: any) =>
        subscribeClientHandle(msg, steps) ? socketNotify(msg) : null
      );
      const userLS = JSON.stringify(loginStatusSelector.data.user);
      localStorage.setItem('USER', userLS);
      // dispatch(setRefreshToken(loginStatusSelector.data.refresh_token));
      redirectApp();
    }
    if (loginStatusSelector.status === 'failed') {
      setLoginError(true);
    }
  }, [loginStatusSelector]);

  return (
    <>
      <Paper
        sx={{
          width: '100%',
          height: '100vh',
          backgroundColor: '#f2f2f2',
        }}
      >
        {token ? (
          <Paper
            sx={{
              elevation: 3,
              width: '100%',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <h1>Already logged in</h1>
              <Button
                onClick={redirectApp}
                sx={{
                  marginTop: '10px',
                  width: '20%',
                  fontWeight: 600,
                  letterSpacing: '1.2pt',
                }}
                variant="contained"
                color="primary"
              >
                <span>ENTER APP</span>
              </Button>
              <Button
                onClick={handleLogout}
                sx={{
                  marginTop: '10px',
                  width: '20%',
                  textDecoration: 'underline',
                  fontWeight: 600,
                  letterSpacing: '1.2pt',
                }}
                variant="text"
                color="error"
                endIcon={<LogoutIcon />}
              >
                <span>LOGOUT</span>
              </Button>
            </Box>
          </Paper>
        ) : (
          <Paper
            sx={{
              elevation: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              height: '100vh',
              backgroundColor: '#f2f2f2',
            }}
          >
            <Paper
              sx={{
                display: 'flex',
                elevation: 3,
                width: 'auto',
                height: 'auto',
                borderRadius: '20px',
                marginBottom: '20px',
                justifyContent: 'center',
                /* backgroundColor: '#f2f2f2', */
              }}
            >
              <h2
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  textEmphasisColor: '#f5f5f5',
                }}
              >
                {TYPE?.toUpperCase()}
              </h2>
            </Paper>
            <Paper
              sx={{
                elevation: 3,
                width: 'auto',
                height: 'auto',
                borderRadius: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                /* backgroundColor: '#f2f2f2' */
              }}
            >
              <form
                onSubmit={handleSubmit}
                style={{
                  width: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '80px',
                }}
              >
                <FormControl sx={{ m: 1.5, width: '30ch' }} variant="outlined">
                  <InputLabel htmlFor="username">Username</InputLabel>
                  <OutlinedInput
                    id="username"
                    label="Username"
                    autoComplete="current-username"
                    onChange={(event) => {
                      loginErrorCheck();
                      setUsername(event.target.value);
                    }}
                  />
                </FormControl>
                <FormControl sx={{ m: 1.5, width: '30ch' }} variant="outlined">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    autoComplete="current-password"
                    onChange={(event) => {
                      loginErrorCheck();
                      setPassword(event.target.value);
                    }}
                  />
                </FormControl>
                {loginError ? (
                  <p style={{ color: 'red' }}>Wrong username or password!</p>
                ) : null}
                <Button
                  style={{ marginTop: '50px', width: 'auto' }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<LoginIcon />}
                >
                  <span>Login</span>
                </Button>
              </form>
            </Paper>
          </Paper>
        )}
      </Paper>
    </>
  );
};

export default Login;
