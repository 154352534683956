import { useEffect, useState } from 'react';
import {
  getAllStonesAsync,
  stonesPerformActionStatus,
} from '../../pages/GeneralView/stonesSlice';
import { useAppDispatch } from '../../app/hooks';
import { TableComponentInterface } from '../../model/tableComponentInterface';
import { useSelector } from 'react-redux';
import { StonesState } from '../../model/stonesInterface';
import TitleTableComponent from '../Title-Table/TitleTableComponent';
import TableComponent from '../Table/TableComponent';
import { TYPE } from '../../config';
import 'moment-timezone';
import MixFiltersComponent from '../MixFiltersBySearchAndDate/MixFiltersComponent';

const StepComponent = ({
  item,
  tableColumn,
  sortable,
  data,
  stepSelected,
  titleHover,
  renderCell,
}: TableComponentInterface) => {
  const dispatch = useAppDispatch();
  const [displayedData, setDisplayedData] = useState(data);

  //*-- STONE PERFORM ACTION - statusSelector
  const postStonesPerformActionAsyncStatusSelector: StonesState['stonesPerformAction'] =
    useSelector(stonesPerformActionStatus);

  //*-------USEFFECT--------------

  useEffect(() => {
    if (postStonesPerformActionAsyncStatusSelector.status === 'success') {
      dispatch(getAllStonesAsync(`${TYPE}`));
    }
  }, [postStonesPerformActionAsyncStatusSelector]);

  useEffect(() => {
    setDisplayedData(data);
  }, [data]);

  return (
    <>
      <div
        style={{
          marginBottom: '2px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '8px',
          overflow: 'auto',
        }}
      >
        <TitleTableComponent stepObject={item} />
        <MixFiltersComponent
          setData={setDisplayedData}
          data={data}
          item={item}
        />
      </div>
      <TableComponent
        data={displayedData}
        columns={tableColumn}
        titleHover={titleHover}
        sortable={sortable}
        renderCell={renderCell}
        dataStep={item}
        stepSelected={stepSelected}
      />
    </>
  );
};

export default StepComponent;
