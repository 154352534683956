import { CSSProperties, useEffect, useState } from 'react';
import { Button, Grid, Hidden, Tooltip } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';
import UserButtonMenuComponent from './UserButtonMenuComponent';
import UserStyleMenuComponent from './UserStyleMenuComponent';
import StyledMenuComponent from './StyledMenuComponent';
import { useSelector } from 'react-redux';
import { stonesLoadStatus } from '../../pages/GeneralView/stonesSlice';
import TitleNavbarComponent from './TitleNavbarComponent';
import { Add, CheckCircle, Equalizer, Update } from '@mui/icons-material';
import {
  getAuthorizedRoles,
  getAuthorizedUserEmail,
  isCreator,
} from '../../utility/token';
import AddNewStoneModalComponent from '../Modal/AddNewStoneModalComponent';
import { stepsLoadStatus } from '../../pages/GeneralView/stepsSlice';
import { StepsState } from '../../model/stepsInterface';
import NotificationDropdown from './NotificationDropdown';
import { store } from '../../app/store';
import { useAppDispatch } from '../../app/hooks';
import { resetNotification } from '../../utility/socket/notificationSlice';
import { typeLoadStatus } from './typeSlice';

const Layout = () => {
  const dispatch = useAppDispatch();
  const pathname = location.pathname;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const userLS = localStorage.getItem('USER') ?? '';
  let user = userLS ? JSON.parse(userLS) : null;

  user = {
    ...user,
    email: getAuthorizedUserEmail(),
    roles: getAuthorizedRoles()?.reduce(
      (rolestring: string, item: string, index: number) => {
        rolestring =
          index > 0
            ? `${rolestring} ${item.split('_')[1]}`
            : `${item.split('_')[1]}`;
        return rolestring;
      },
      ''
    ),
  };

  const [numTot, setNumTot] = useState(0);
  const [numWip, setNumWip] = useState(0);
  const [numDone, setNumDone] = useState(0);
  const [openAddStoneVisible, setOpenAddStoneVisible] = useState<any>(Boolean);
  const [notificationList, setNotificationList] = useState<any>([]);

  //*-- TYPE - statusSelector
  const getAllTypeStatusSelector: any = useSelector(typeLoadStatus);

  //*-- STONES - statusSelector
  const getAllStonesStatusSelector: any = useSelector(stonesLoadStatus);

  //*-- STEPS - statusSelector
  const getAllStepsStatusSelector: StepsState['stepsLoad'] =
    useSelector(stepsLoadStatus);

  const mr: CSSProperties = { marginRight: '0.2em' };
  const flexLabel: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  };

  const handleCloseNewStoneModal = () => {
    setOpenAddStoneVisible(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearNotification: any = () => {
    setNotificationList([]);
    dispatch(resetNotification());
    localStorage.removeItem('notifications');
  };

  const AddStoneButton = () => {
    if (pathname === '/home' || pathname === '/') {
      return isCreator(getAllTypeStatusSelector?.data?.allowedCreatorRoles) ? (
        <Tooltip title="Aggiungi Reperto" arrow>
          <Button
            sx={{
              marginRight: '15px',
              padding: '10px',
              borderRadius: '25px',
              minWidth: 'unset',
            }}
            variant="contained"
            onClick={() => setOpenAddStoneVisible(true)}
          >
            <Add />
          </Button>
        </Tooltip>
      ) : null;
    }
  };

  //--USEFFECTS------------------------------------------------

  useEffect(() => {
    setNotificationList(store?.getState()?.notification?.notificationList);
  }, []);

  useEffect(() => {
    setNotificationList(store?.getState()?.notification?.notificationList);
  }, [store?.getState()?.notification]);

  useEffect(() => {
    if (getAllStonesStatusSelector.status === 'success') {
      const filterWip = getAllStonesStatusSelector.data.filter((item: any) => {
        const obj = item.steps.filter(
          (item: any) => item.stepId !== 'DONE' && item.stepId !== 'FULL_LIST'
        );
        return obj.length > 0;
      });
      const filterDone = getAllStonesStatusSelector.data.filter((item: any) => {
        const obj = item.steps.filter((item: any) => item.stepId === 'DONE');
        return obj.length > 0;
      });
      setNumTot(getAllStonesStatusSelector.data.length);
      setNumWip(filterWip.length);
      setNumDone(filterDone.length);
    }
  }, [getAllStonesStatusSelector]);

  return (
    <div>
      <Grid item xs={12}>
        <div
          style={{
            height: 'auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '10px',
            marginBottom: '28px',
            backgroundColor: 'white',
            width: '100%',
            borderBottom: '1px solid #9c9c9c',
          }}
        >
          <TitleNavbarComponent />
          <Hidden only={'xs'}>
            <Grid
              container
              spacing={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid style={flexLabel} item>
                <Equalizer style={{ ...mr, color: 'rgb(35, 140, 216)' }} />
                <Hidden mdDown>
                  <span>{'Totali :'}</span>&nbsp;
                </Hidden>
                {numTot}
              </Grid>
              <Grid style={flexLabel} item>
                <Update style={{ ...mr, color: '#ff9800' }} />
                <Hidden mdDown>
                  <span>{'In lavorazione :'}</span>&nbsp;
                </Hidden>
                {numWip}
              </Grid>
              <Grid style={flexLabel} item>
                <CheckCircle style={{ ...mr, color: '#4caf50' }} />
                <Hidden mdDown>
                  <span>{'Completati :'}</span>&nbsp;
                </Hidden>
                {numDone}
              </Grid>
            </Grid>
          </Hidden>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <NotificationDropdown
              notificationList={notificationList}
              resetNotification={clearNotification}
              steps={getAllStepsStatusSelector.data}
            />
            {AddStoneButton()}
            <UserButtonMenuComponent
              open={open}
              handleClick={handleClick}
              alpha={alpha}
              user={user}
            />
            <StyledMenuComponent
              id="customized-menu"
              MenuListProps={{
                'aria-labelledby': 'customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <UserStyleMenuComponent user={user} />
            </StyledMenuComponent>
          </div>
        </div>
      </Grid>
      {openAddStoneVisible ? (
        <AddNewStoneModalComponent
          className="AddNewStoneModal"
          open={openAddStoneVisible}
          onClose={() => handleCloseNewStoneModal()}
          stepForm={getAllStepsStatusSelector.data}
        />
      ) : null}
    </div>
  );
};

export default Layout;
