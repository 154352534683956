import { Client } from '@stomp/stompjs';
import { REACT_APP_WS_URL } from '../../config';

const clients: Array<Client> = [];

const stompSocket = (queue: any, token: string, subCallback: any) => {
  const url = REACT_APP_WS_URL;

  const client = new Client({
    brokerURL: url,
    reconnectDelay: 5000,

    onConnect: () => {
      // console.log('Connected');
      client.subscribe(queue, (msg: any) => subCallback(msg));
    },

    onDisconnect: () => {
      // console.log('Disconnected');
    },

    connectHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });

  clients.push(client);

  try {
    client.activate();
  } catch (error) {
    // console.error(`Errore durante la connessione al WebSocket: ${error}`);
    stompSocket(queue, token, subCallback);
  }
};

// Funzione per disconnettersi dalla WebSocket
const disconnectWebSocket = (brokerURL: any) => {
  const client = clients.find((c) => c.connected && c.brokerURL === brokerURL);
  if (client) {
    // console.log(`Disconnecting from ${client.brokerURL}`);
    client.deactivate();
  } else {
    // console.warn(`Client with brokerURL ${brokerURL} not found`);
  }
};

export { stompSocket, disconnectWebSocket };
