import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ChartLineState } from '../../model/chartsInterface';
import { useCallback } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChartComponent = ({
  dataProps,
  dataLabel,
  legendPosition,
  chartTitle,
}: ChartLineState) => {
  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: legendPosition,
      },
      title: {
        display: chartTitle ? true : false,
        text: chartTitle,
      },
    },
  };

  const refreshData = useCallback(() => {
    const lineChartData = {
      labels: dataProps.length > 0 ? dataLabel : [],
      datasets: dataProps.length > 0 ? dataProps : [{label: 'N/D', data: []}],
    };
    return lineChartData;
  }, [dataProps]);

  return dataProps ? (
    <Line options={options} data={refreshData()} />
  ) : (
    <div>Nessun dato disponibile</div>
  );
};

export default LineChartComponent;