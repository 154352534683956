import { AppBar, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { TYPE } from '../../config';

const Footer: React.FC = () => {
  const footerLabel = `${TYPE?.toUpperCase()}`;
  const currentYear = new Date().getFullYear();
  return (
    <AppBar position="static" color="primary" style={{ borderRadius: '8px' }}>
      <Toolbar style={{ minHeight: '2em' }}>
        <Typography
          variant="body1"
          color="inherit"
          style={{ flexGrow: 1, textAlign: 'center' }}
        >
          © {currentYear} · {footerLabel}
        </Typography>
        {/* <div style={{ flexGrow: 1 }} />
        <Typography variant="body1" color="inherit">
          SIGEO S.R.L.
        </Typography> */}
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
