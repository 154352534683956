import { Container, Grid } from '@mui/material';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';

const Layout = ({ children }: any) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Navbar />
      </Grid>
      <Grid
        item
        style={{
          minHeight: 'calc(((100vh - 76px) - 33px) - 40px)'
        }}
      >
        <Container style={{ maxWidth: 'unset',paddingBottom: '120px' }}>{children}</Container>
      </Grid>
      <Grid item sx={{ margin: '0 0.6em 0.6em 0.6em'}}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Layout;
