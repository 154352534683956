const stepDisplayProps = (stepsArray: any, targetStep: string) => {
  const stepsWithProps = stepsArray?.map((step: any) => {
    const stepId = step?.id;
    const stepLabel = step?.label;
    const icon = step?.props?.svgProps?.svgIcon;
    const size = step?.props?.svgProps?.size;
    const color = step?.props?.svgProps?.color;
    return {
      stepId: stepId,
      stepLabel: stepLabel,
      svgProps: {
        svgIcon: icon,
        size: size,
        color: color
      }
    };
  });
  const targetObj = stepsWithProps?.filter(
    (el: any) => el?.stepId === targetStep
  );
  return targetObj?.[0] ?? null;
};

export { stepDisplayProps };
