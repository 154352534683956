import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchLogin } from '../../services/userAPI';
import { RootState } from '../../app/store';
import { LoginState } from '../../model/loginInterface';
import axios from 'axios';
import { _getConfig } from '../../utility/headers';
import { REACT_APP_API_URL } from '../../config';
const initialState: LoginState = {
  data: null,
  status: 'idle',
};

export const loginAsync = createAsyncThunk(
  'login/fetchLogin',
  async (data: any) => {
    try {
      const response: any = await axios
        .post(
          `${REACT_APP_API_URL}/user/login`,
          {
            username: data.username,
            password: data.password,
          },
          _getConfig(false)
        )
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ? e.data : e);
    }
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLogin: (state) => {
      state.status = 'idle';
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state: any) => {
        state.status = 'loading';
      })
      .addCase(loginAsync.fulfilled, (state: any, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(loginAsync.rejected, (state: any, action) => {
        state.status = 'failed';
        state.data = action.error;
      });
  },
});

export const { resetLogin } = loginSlice.actions;
export const loginState = (state: RootState) => state.login;
export default loginSlice.reducer;
