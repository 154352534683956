import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { useAppDispatch } from '../../app/hooks';
import CloseIcon from '@mui/icons-material/Close';
import {
  deleteUserAsync,
  resetDeleteUser,
} from '../../pages/Users/usersSlice';
import { useEffect } from 'react';

const DeleteUserModalComponent = ({ open, onClose, rowUser }: any) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    onClose();
  };

  useEffect(() => {
    return () => {
      dispatch(resetDeleteUser());
    };
  }, []);
  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ height: '20px', width: '98%' }}
      >
        <Grid item></Grid>
        <Grid item>
          <IconButton
            edge="end"
            onClick={handleCloseModal}
            style={{ padding: '24px' }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DialogTitle id="alert-dialog-title">
        {'Conferma eliminazione'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Sei sicuro di voler eliminare &nbsp;<b>{rowUser.name}</b>&nbsp; ?
          Questa azione non può essere annullata.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleCloseModal();
            dispatch(deleteUserAsync(rowUser.id));
          }}
          color="error"
          autoFocus
        >
          <span>Elimina</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserModalComponent;
