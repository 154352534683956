const actionLabelSwitch = (action: string) => {
  switch (action) {
    case 'TAKE_CHARGE':
      return 'Presa in carico';
    case 'REJECT':
      return 'Rifiuta';
    case 'FINISH':
      return 'Completa';
    default:
      break;
  }
};

const actionNotificationLabelSwitch = (action: string) => {
  switch (action) {
    case 'TAKE_CHARGE':
      return 'Preso in carico';
    case 'REJECT':
      return 'Rifiutato';
    case 'FINISH':
      return 'Completato';
    case 'CHANGE_STEP':
      return 'Completato';
    case 'CREATE':
      return 'Creato';
    case 'DELETE':
      return 'Eliminato';
    case 'EDIT':
      return 'Modificato';
    default:
      return action ?? 'N/D';
  }
};

export { actionLabelSwitch, actionNotificationLabelSwitch };
