import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { useSelector } from 'react-redux';
import { Grid, Paper, Button, Tooltip } from '@mui/material';
import Layout from '../Layout/Layout';
import {
  dataIntoStoneStatus,
  getAllStonesAsync,
  postStonesPerformActionAsync,
  putDataIntoStoneStatus,
  resetDataIntoStone,
  resetPutDataIntoStone,
  resetStonesLoad,
  resetStonesPerformAction,
  stonesAddStatus,
  stonesLoadStatus,
} from './stonesSlice';
import { StonesState } from '../../model/stonesInterface';
import { StepsState } from '../../model/stepsInterface';
import {
  getAllStepsAsync,
  resetStepsLoad,
  stepsLoadStatus,
} from './stepsSlice';
import { resetFormPHOTO } from '../../components/Modal/ModalComponentSlice';
import StepComponent from '../../components/StepComponent/StepComponent';
import ToastNotification from '../../components/toastNotification/ToastNotification';
import { toast } from 'react-toastify';
import CopyToClipboardComponent from '../../components/CopyToClipboard/CopyToClipboardComponent';
import FormModalComponent from '../../components/Modal/FormModalComponent';
import LoadingSkeletonComponent from '../../components/Loading/LoadingSkeletonComponent';
import InfoModalComponent from '../../components/Modal/InfoModalComponent';
import { TYPE } from '../../config';
import { toastDefaultProps } from '../../utility/toastBodyProps';
import {
  getAllTypeAsync,
  resetTypeLoad,
  typeLoadStatus,
} from '../../components/Navbar/typeSlice';
import { TypeState } from '../../model/typeInterface';
import { actionLabelSwitch } from '../../utility/actions/actionLabels';
import ActionIcons from '../../utility/actions/ActionIcons';
import { renderValueByPath } from '../../utility/renderValueByPath/renderValueByPath';

const GeneralView = () => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataType, setDataType] = useState<any>();
  const [dataStones, setDataStones] = useState<any>([]);
  const [dataSteps, setDataSteps] = useState<any>([]);
  const [stepSelected, setStepSelected] = useState<string>();
  const [infoStoneSelected, setInfoStoneSelected] = useState<any>([]);
  const [openInfoModalVisible, setOpenInfoModalVisible] = useState(Boolean);
  const [openFormModalVisible, setOpenFormModalVisible] = useState(Boolean);
  const [formBody, setFormBody] = useState<any>({});

  const postNewDataIntoStoneStatusSelector = useSelector(dataIntoStoneStatus);

  const putDataIntoStoneStatusSelector = useSelector(putDataIntoStoneStatus);

  const postStonesStatusSelector: StonesState['stonesAdd'] =
    useSelector(stonesAddStatus);

  //*-- TYPE - StatusSelector
  const getAllTypeStatusSelector: TypeState['typeLoad'] =
    useSelector(typeLoadStatus);

  //*-- STONES - statusSelector
  const getAllStonesStatusSelector: StonesState['stonesLoad'] =
    useSelector(stonesLoadStatus);

  //*-- STEPS - statusSelector
  const getAllStepsStatusSelector: StepsState['stepsLoad'] =
    useSelector(stepsLoadStatus);

  const handleCloseInfoModal = () => {
    setOpenInfoModalVisible(false);
  };

  const handleCloseFormModal = () => {
    setOpenFormModalVisible(false);
  };

  const titleHover: any = (dataObj: any, headerObj: any) => {
    const value = renderValueByPath(dataObj, headerObj);
    return value ?? null;
  };

  const renderCell: any = (dataObj: any, headerObj: any, stepId: string) => {
    const noWrap: any = { whiteSpace: 'nowrap' };
    const value = renderValueByPath(dataObj, headerObj);
    switch (headerObj?.path) {
      case 'code':
        return (
          <span
            className="tableCellCode"
            style={{ ...noWrap, display: 'flex', justifyContent: 'center' }}
          >
            <CopyToClipboardComponent value={dataObj?.code} />
            <Tooltip title="Info" placement="right" arrow>
              <p
                onClick={() => {
                  setInfoStoneSelected(dataObj);
                  setStepSelected(stepId);
                  setOpenInfoModalVisible(true);
                }}
                style={{
                  cursor: 'pointer',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  marginTop: '8px',
                }}
              >
                {dataObj?.code}
              </p>
            </Tooltip>
          </span>
        );

      case 'actions':
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {dataObj?.steps?.[0]?.actions
              ?.filter(
                (el: string) =>
                  el !== 'READ' &&
                  el !== 'CREATE' &&
                  el !== 'DELETE' &&
                  el !== 'EDIT'
              )
              ?.map((action: any, i: number) => {
                return (
                  <div key={i} style={{ ...noWrap, display: 'flex' }}>
                    <Tooltip title={actionLabelSwitch(action)} arrow>
                      <Button
                        sx={{
                          marginLeft: i > 0 ? '15px' : 'unset',
                          padding: '4px',
                          border: '1px solid whitesmoke',
                          backgroundColor: 'rgb(0 0 0 / 8%)',
                          borderRadius: '25px',
                          minWidth: 'unset',
                        }}
                        onClick={() => {
                          const stoneType = dataObj?.stoneType;
                          const stoneCode = dataObj?.code;
                          const body = {
                            stoneId: dataObj?.id,
                            code: stoneCode,
                            action: action,
                            stepId: dataObj?.steps?.[0]?.stepId,
                            params: null,
                            data: dataObj?.data,
                          };

                          setFormBody({
                            body,
                            stoneType,
                            stoneCode,
                          });
                          dataObj?.steps?.[0]?.stepId === 'PHOTO' &&
                          dataObj?.steps?.[0]?.status === 'TODO'
                            ? setOpenFormModalVisible(true)
                            : dispatch(
                              postStonesPerformActionAsync({
                                stoneType,
                                body,
                              })
                            );
                        }}
                      >
                        <ActionIcons action={action} />
                      </Button>
                    </Tooltip>
                  </div>
                );
              })}
          </div>
        );

      default:
        return value ?? 'N/D';
    }
  };

  //*--- DATA FETCHED FROM STONES & STEPS & Reset stato GET
  useEffect(() => {
    dispatch(getAllTypeAsync(`${TYPE}`));
    dispatch(getAllStonesAsync(`${TYPE}`));
    dispatch(getAllStepsAsync(`${TYPE}`));
    return () => {
      dispatch(resetTypeLoad());
      dispatch(resetStonesLoad());
      dispatch(resetStepsLoad());
      dispatch(resetFormPHOTO());
      dispatch(resetDataIntoStone());
      dispatch(resetPutDataIntoStone());
      dispatch(resetStonesPerformAction());
    };
  }, []);

  //*--- GET ALL - Type Status
  useEffect(() => {
    if (getAllTypeStatusSelector.status === 'success') {
      const data: any = getAllTypeStatusSelector.data;
      setDataType(data);
    }
  }, [getAllTypeStatusSelector]);

  //*--- GET ALL - Stones Status
  useEffect(() => {
    if (getAllStonesStatusSelector.status === 'success') {
      const data: any = getAllStonesStatusSelector.data;
      setDataStones(data);
    }
  }, [getAllStonesStatusSelector]);

  //*--- GET ALL - Steps Status
  useEffect(() => {
    if (getAllStepsStatusSelector.status === 'success') {
      setDataSteps(getAllStepsStatusSelector.data);
    }
  }, [getAllStepsStatusSelector]);

  //*--- POST ONE - Stones Status
  useEffect(() => {
    if (postStonesStatusSelector.status === 'success') {
      dispatch(getAllStonesAsync(`${TYPE}`));
      setTimeout(() => {
        toast.success(
          `Reperto: ${postStonesStatusSelector?.data?.code} aggiunto con successo!`,
          toastDefaultProps
        );
      }, 300);
    }
  }, [postStonesStatusSelector]);

  // //*--- POST NEW DATA - Stones Status
  useEffect(() => {
    if (postNewDataIntoStoneStatusSelector.status === 'success') {
      setTimeout(() => {
        toast.info(
          `Codice: ${formBody?.stoneCode} copiato!`,
          toastDefaultProps
        );
      }, 500);
      dispatch(postStonesPerformActionAsync(formBody));
    }
  }, [postNewDataIntoStoneStatusSelector]);

  // //*--- PUT DATA - Stones Status
  useEffect(() => {
    if (putDataIntoStoneStatusSelector.status === 'success') {
      setTimeout(() => {
        toast.info(
          `Codice: ${formBody?.stoneCode} copiato!`,
          toastDefaultProps
        );
      }, 500);
      dispatch(postStonesPerformActionAsync(formBody));
    }
  }, [putDataIntoStoneStatusSelector]);

  const loopTable = () => {
    const dataStepsSortedByOrderNum = [...dataSteps];
    return (
      <>
        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
          {dataStepsSortedByOrderNum
            ?.sort((a: any, b: any) => a?.orderNum - b?.orderNum)
            ?.map((item: any, i: number) => {
              const tableSortable = item?.props?.tableSortable;
              const tableColumnChosen = item?.props?.tableColumn?.filter(
                (el: any) => el?.show
              );
              const columnPropsByOrderNum = tableColumnChosen && [
                ...tableColumnChosen,
              ];
              const stepWidth = item?.props?.stepWidth;
              const xs = stepWidth?.xs ?? 12;
              const md = stepWidth?.md ?? 12;
              const xl = stepWidth?.xl ?? 12;
              return (
                <Grid item xs={xs} md={md} xl={xl} key={i}>
                  <Paper sx={{ padding: '0.5em 0em 0.5em 0em' }}>
                    <div key={i} style={{ margin: '15px' }}>
                      <StepComponent
                        setDataSpread={setDataStones}
                        className={'TableList'}
                        dataStep={item}
                        data={dataStones.filter((el: any) =>
                          el?.steps?.[0]?.stepId?.includes(item?.id?.toString())
                        )}
                        styleHieght={'auto'}
                        item={item}
                        tableColumn={columnPropsByOrderNum?.sort(
                          (a: any, b: any) => a?.orderNum - b?.orderNum
                        )}
                        titleHover={titleHover}
                        sortable={tableSortable ?? false}
                        renderCell={renderCell}
                        stepSelected={item?.id?.toString()}
                      />
                    </div>
                  </Paper>
                </Grid>
              );
            })}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Layout>
        {dataSteps && dataSteps.length > 0 ? (
          loopTable()
        ) : (
          <LoadingSkeletonComponent skeletons={24} />
        )}
        {openInfoModalVisible ? (
          <InfoModalComponent
            className="InfoModal"
            open={openInfoModalVisible}
            onClose={() => handleCloseInfoModal()}
            stoneSelected={infoStoneSelected}
            dataSteps={dataSteps}
            stepSelected={stepSelected}
          />
        ) : null}
        {openFormModalVisible ? (
          <FormModalComponent
            open={openFormModalVisible}
            onClose={() => handleCloseFormModal()}
            formBody={formBody?.body}
          />
        ) : null}
        <ToastNotification />
      </Layout>
    </>
  );
};

export default GeneralView;
