import LineChartComponent from '../../components/charts/LineChartComponent';
import 'rsuite/dist/rsuite.min.css';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import { stepsProfiledVisible } from '../../utility/socket/stepsProfiled';
import { reduceOpacity } from '../../utility/color/colorManipulation';
import DateRangePickerComponent from '../../components/DatePicker/DateRangePickerComponent';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { stepDisplayProps } from '../../utility/stepsInterpolation/stepDisplayProps';
import SvgComponent from '../../components/SVG/SvgComponent';

const LineChart = ({ steps, history }: any) => {
  require('dayjs/locale/it');
  dayjs.locale('IT');
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  const theme = useTheme();
  const stepsVisible = stepsProfiledVisible(steps);
  const [dateRange, setDateRange] = useState<any>([
    new Date(new Date().getFullYear(), 0, 1),
    new Date(),
  ]);
  const [data, setData] = useState<any>([]);
  const [labels, setLabels] = useState<any>([]);
  const [selectedSteps, setSelectedSteps] = useState<any>([]);

  //*-- LINE CHART DATA

  const lineLegendPosition: any = 'top';
  const lineChartTitle: any = '';

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedSteps(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const getStyles = (name: any, selectedSteps: any, theme: any) => {
    return {
      fontWeight:
        selectedSteps.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const filterCombinedDatesForLineChart = (
    dateRange: [Date | undefined, Date | undefined]
  ) => {
    const [start, end] = dateRange;
    const startDate = dayjs(start).date();
    const startMonth = dayjs(start).month();
    const startYear = dayjs(start).year();
    const endDate = dayjs(end).date();
    const endMonth = dayjs(end).month();
    const endYear = dayjs(end).year();

    if (startMonth === endMonth && startYear === endYear) {
      // Date range within a single month and year
      const daysInMonth = dayjs(start).daysInMonth();
      const filteredData = history.filter(
        ({ date, stepId }: any) =>
          dayjs(date).year() === startYear &&
          dayjs(date).month() === startMonth &&
          dayjs(date).date() >= startDate &&
          dayjs(date).date() <= endDate &&
          selectedSteps.includes(stepId)
      );

      const countsArrays = selectedSteps.map((stepId: any) => {
        const counts = new Array(daysInMonth).fill(0);
        filteredData.forEach(({ date, stepId: filteredStepId, event }: any) => {
          if (filteredStepId === stepId && event === 'CHANGE_STEP') {
            const index = dayjs(date).date() - 1;
            counts[index]++;
          }
        });
        return counts;
      });

      const newDatasetsOneMonth = selectedSteps.map(
        (stepId: any, i: number) => {
          const stepTargetProps = stepDisplayProps(steps, stepId);
          return {
            label: stepTargetProps?.stepLabel,
            data: countsArrays[i],
            borderColor: stepTargetProps?.svgProps?.color,
            backgroundColor: reduceOpacity(
              stepTargetProps?.svgProps?.color,
              40
            ),
          };
        }
      );
      const labels = new Array(daysInMonth).fill('').map((_, i) => {
        const dayOfMonth = i + 1;
        const monthAbbreviation = dayjs()
          .date(dayOfMonth)
          .month(startMonth)
          .format('MMM');
        const yearAbbreviation = dayjs()
          .date(dayOfMonth)
          .month(startMonth)
          .year(startYear)
          .format('YYYY');
        const monthFirstLetterSingleUpperCase = monthAbbreviation
          .charAt(0)
          .toUpperCase();
        return `${
          monthFirstLetterSingleUpperCase + monthAbbreviation.slice(1)
        } ${dayOfMonth}, ${yearAbbreviation}`;
      });
      setData(newDatasetsOneMonth);
      setLabels(labels);
    } else {
      // Date range spanning multiple months and/or years
      const filteredData = history.filter(({ date, stepId }: any) => {
        const currentDay = dayjs(date);
        return (
          currentDay.isSameOrAfter(dayjs(start), 'day') &&
          currentDay.isSameOrBefore(dayjs(end), 'day') &&
          selectedSteps.includes(stepId)
        );
      });

      const filteredSteps = Array.from(
        new Set(filteredData.map(({ stepId }: any) => stepId))
      );

      const allDates: any = [];
      let currentDay: any = dayjs(start);
      while (currentDay?.isSameOrBefore(dayjs(end), 'day')) {
        allDates.push(currentDay);
        currentDay = currentDay.add(1, 'day');
      }

      const newDatasets = filteredSteps.map((step: any) => {
        const counts = new Array(allDates.length).fill(0);
        const stepTargetProps = stepDisplayProps(steps, step);
        filteredData.forEach(({ date, stepId, event }: any) => {
          if (stepId === step && event === 'CHANGE_STEP') {
            const dateIndex = allDates.findIndex((d: any) =>
              dayjs(date).isSame(d, 'day')
            );
            counts[dateIndex]++;
          }
        });
        return {
          label: stepTargetProps?.stepLabel,
          data: counts,
          borderColor: stepTargetProps?.svgProps?.color,
          backgroundColor: reduceOpacity(stepTargetProps?.svgProps?.color, 40),
        };
      });
      const labels = allDates.map((d: any) => {
        const dayAndYearAbbreviation = d.format('D, YYYY');
        const monthAbbreviation = d.format('MMM');
        const monthFirstLetterUpperCase = monthAbbreviation
          .charAt(0)
          .toUpperCase();
        const monthAbbreviationRest = monthAbbreviation.slice(1);
        return `${monthFirstLetterUpperCase}${monthAbbreviationRest} ${dayAndYearAbbreviation}`;
      });
      setData(newDatasets);
      setLabels(labels);
    }
  };

  //------ USEFFECTS -------------------------------

  useEffect(() => {
    if (dateRange === null) {
      setData([]);
      return;
    }
    filterCombinedDatesForLineChart(dateRange);
  }, [dateRange, selectedSteps, history]);

  useEffect(() => {
    const lastStepDefaultValue: any = steps?.slice(-1);
    setSelectedSteps([lastStepDefaultValue?.[0]?.id]);
  }, [steps]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ margin: 1 }}>
          <DateRangePickerComponent
            disabled={false}
            size={'lg'}
            setData={setDateRange}
            defaultValue={dateRange}
            placeholder={'Seleziona intervallo di Date'}
            style={{ width: 'auto' }}
          />
        </div>
        {steps && steps.length > 0 ? (
          <FormControl sx={{ m: 1, minWidth: 170, width: 'auto' }}>
            <InputLabel id="">Steps</InputLabel>
            <Select
              disabled={true}
              size="medium"
              labelId=""
              id=""
              multiple
              value={selectedSteps}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected: any) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value: any, i: number) => {
                    const stepTargetProps = stepDisplayProps(steps, value);
                    return (
                      <Chip
                        key={i}
                        label={stepTargetProps?.stepLabel}
                        icon={
                          <SvgComponent svgProps={stepTargetProps?.svgProps} />
                        }
                      />
                    );
                  })}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {stepsVisible.map((name: any, i: number) => {
                const stepTargetProps = stepDisplayProps(steps, name);
                return (
                  <MenuItem
                    key={i}
                    value={name}
                    style={getStyles(name, selectedSteps, theme)}
                  >
                    <SvgComponent svgProps={stepTargetProps?.svgProps} />
                    <span style={{ marginLeft: '6px'}}>
                      {stepDisplayProps(steps, name)?.stepLabel}
                    </span>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : null}
      </div>
      <div
        style={{
          width: '100%',
          height: '600px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LineChartComponent
          dataProps={data}
          dataLabel={labels}
          legendPosition={lineLegendPosition}
          chartTitle={lineChartTitle}
        />
      </div>
    </>
  );
};

export default LineChart;
