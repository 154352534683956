import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Notifications from '@mui/icons-material/Notifications';
import StyledMenuComponent from './StyledMenuComponent';
import { useState } from 'react';
import { Refresh } from '@mui/icons-material';
import { Divider } from '@mui/material';
import MessageHTML from '../../utility/socket/MessageHTML';

const NotificationDropdown = ({ notificationList, resetNotification, steps }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const notificationCount: number = notificationList?.length;
  const reverseNotificationArray: any = notificationList
    ? [...notificationList]?.reverse()
    : [];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ marginRight: '25px' }}>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={notificationCount} color="error">
          <Notifications style={{ fontSize: '30px', fill: 'rgb(234 169 0)' }} />
        </Badge>
      </IconButton>
      <StyledMenuComponent
        className="notificationDropdown"
        MenuListProps={{
          'aria-labelledby': 'customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className="socketNotificationDropdown">
          {notificationList && notificationList?.length > 0 ? (
            reverseNotificationArray?.map((el: any, i: number) => {
              return (
                <div key={i}>
                  {i === 0 ? (
                    <>
                      <div
                        className="socketNotificationRefresh"
                        onClick={resetNotification}
                      >
                        <Refresh />
                        <h6>Pulisci notifiche</h6>
                      </div>
                      <Divider />
                    </>
                  ) : null}
                  {i > 0 ? <Divider /> : null}
                  <MessageHTML item={el} steps={steps} />
                </div>
              );
            })
          ) : (
            <h6>Non ci sono nuove notifiche</h6>
          )}
        </div>
      </StyledMenuComponent>
    </div>
  );
};

export default NotificationDropdown;
