import _ from 'lodash';
import { deepPathValue } from '../deepPathValue/deepPathValue';
import { formatDate } from '../formatDate/formatDate';

const renderValueByPath = (data: any, targetObj: any) => {
  const format: string = targetObj?.format;
  const type: string = targetObj?.type;
  const path: string = targetObj?.path;
  let value: any = _.at(data, path)[0];

  if (path.match(/\(([^)]+)\)/g)) {
    const valueFromDeepPath = deepPathValue(data, path);
    value = valueFromDeepPath?.toString();
    if (format === '') {
      return value;
    }
  }

  // se tipo String e format è diverso da ''
  if ((format !== '' && type === 'string') || type === 'number') {
    const formattedString: string = value
      ? format.replace('{val}', value)
      : 'N/D';
    value = formattedString;
  }

  // se tipo Date
  if (type === 'date') {
    if (!value) return 'N/D';
    const formattedDate: string =
      format !== ''
        ? formatDate(value, format)
        : formatDate(value, 'DD/MM/YY · HH:mm:ss');
    value = formattedDate;
  }

  return value ?? 'N/D';
};

export { renderValueByPath };
