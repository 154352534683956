import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { REACT_APP_API_URL, TYPE } from '../../config';
import { StonesState } from '../../model/stonesInterface';
import { _getConfig } from '../../utility/headers';

//*-1- GET ALL --  /stones/type/{stoneType}

//*-2- POST ONE -- /stones/type/{stoneType}

//*-3- POST SET OWNER OF ONE STONE -- /stones/type/{stoneType}/{stoneId}/own

//*-4- POST PERFORM ACTION ON ONE STONE -- /stones/type/{stoneType}/{id}/action

//*-5- PUT ONE -- /stones/type/{stoneType}/{stoneId}

//*-6- POST NEW DATA INTO STONE  -- /stone/type/pompei/data

//*-7- PUT DATA INTO STONE  -- /stone/type/pompei/data

const initialState: StonesState = {
  stonesLoad: {
    data: null,
    status: 'idle',
  },
  stonesAdd: {
    data: null,
    status: 'idle',
  },
  stonesOwnerAdd: {
    data: null,
    status: 'idle',
  },
  stonesPerformAction: {
    data: null,
    status: 'idle',
  },
  stonesMod: {
    data: null,
    status: 'idle',
  },
  dataIntoStone: {
    data: null,
    status: 'idle',
  },
  putDataIntoStone: {
    data: null,
    status: 'idle',
  }
};

//*-1- GET ALL
export const getAllStonesAsync = createAsyncThunk(
  'stones/getAllStones',
  async (type: string) => {
    try {
      const response: any = await axios
        .get(`${REACT_APP_API_URL}/stones/type/${type}`, _getConfig(true))
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

//*-2- POST ONE
export const postStonesAsync = createAsyncThunk(
  'stones/postStones',
  async (body: any) => {
    try {
      const response: any = await axios
        .post(
          `${REACT_APP_API_URL}/stones/type/${TYPE}`,
          body,
          _getConfig(true)
        )
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

//*-3- POST SET OWNER OF ONE STONE
export const postStonesOwnerAsync = createAsyncThunk(
  'stones/postStonesOwner',
  async ({ body, stoneId }: { body: any; stoneId: string }) => {
    try {
      const response: any = await axios
        .post(
          `${REACT_APP_API_URL}/stones/type/${body?.stoneType?.id}/${stoneId}/own`,
          body,
          _getConfig(true)
        )
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

//*-4- POST PERFORM ACTION ON ONE STONE
export const postStonesPerformActionAsync = createAsyncThunk(
  'stones/postStonesPerformAction',
  async ({ stoneType, body }: { stoneType: string; body: any }) => {
    try {
      const response: any = await axios
        .post(
          `${REACT_APP_API_URL}/stones/type/${stoneType}/action`,
          body,
          _getConfig(true)
        )
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

//*-5- PUT ONE
export const putStonesAsync = createAsyncThunk(
  'stones/putStones',
  async ({ body, stoneId }: { body: any; stoneId: string }) => {
    try {
      const response: any = await axios
        .put(
          `${REACT_APP_API_URL}/stones/type/${body?.stoneType?.id}/${stoneId}`,
          body,
          _getConfig(true)
        )
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

//*-6- POST NEW DATA INTO STONE
export const postNewDataIntoStoneAsync = createAsyncThunk(
  'stones/postDataIntoStone',
  async (body: any) => {
    try {
      const response: any = await axios
        .post(
          `${REACT_APP_API_URL}/stones/type/${TYPE}/data`,
          body,
          _getConfig(true)
        )
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

//*-7- PUT DATA INTO STONE
export const putDataIntoStoneAsync = createAsyncThunk(
  'stones/putDataIntoStone',
  async (body: any) => {
    try {
      const response: any = await axios
        .put(
          `${REACT_APP_API_URL}/stones/type/${TYPE}/data`,
          body,
          _getConfig(true)
        )
        .then((response: any) => {
          return response;
        });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e.data ?? e);
    }
  }
);

export const stonesSlice = createSlice({
  name: 'stones',
  initialState,
  reducers: {
    resetStonesLoad: (state) => {
      state.stonesLoad.status = 'idle';
      state.stonesLoad.data = null;
    },
    resetStonesAdd: (state) => {
      state.stonesAdd.status = 'idle';
      state.stonesAdd.data = null;
    },
    resetStonesOwnerAdd: (state) => {
      state.stonesOwnerAdd.status = 'idle';
      state.stonesOwnerAdd.data = null;
    },
    resetStonesPerformAction: (state) => {
      state.stonesPerformAction.status = 'idle';
      state.stonesPerformAction.data = null;
    },
    resetStonesMod: (state) => {
      state.stonesMod.status = 'idle';
      state.stonesMod.data = null;
    },
    resetDataIntoStone: (state) => {
      state.dataIntoStone.status = 'idle';
      state.dataIntoStone.data = null;
    },
    resetPutDataIntoStone: (state) => {
      state.putDataIntoStone.status = 'idle';
      state.putDataIntoStone.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStonesAsync.pending, (state) => {
        state.stonesLoad.status = 'loading';
      })
      .addCase(getAllStonesAsync.fulfilled, (state: any, action) => {
        state.stonesLoad.status = 'success';
        state.stonesLoad.data = action.payload;
      })
      .addCase(getAllStonesAsync.rejected, (state: any, action) => {
        state.stonesLoad.status = 'failed';
        state.stonesLoad.data = action.error;
      })
      .addCase(postStonesAsync.pending, (state) => {
        state.stonesAdd.status = 'loading';
      })
      .addCase(postStonesAsync.fulfilled, (state: any, action) => {
        state.stonesAdd.status = 'success';
        state.stonesAdd.data = action.payload;
      })
      .addCase(postStonesAsync.rejected, (state: any, action) => {
        state.stonesAdd.status = 'failed';
        state.stonesAdd.data = action.error;
      })
      .addCase(postStonesOwnerAsync.pending, (state) => {
        state.stonesOwnerAdd.status = 'loading';
      })
      .addCase(postStonesOwnerAsync.fulfilled, (state: any, action) => {
        state.stonesOwnerAdd.status = 'success';
        state.stonesOwnerAdd.data = action.payload;
      })
      .addCase(postStonesOwnerAsync.rejected, (state: any, action) => {
        state.stonesOwnerAdd.status = 'failed';
        state.stonesOwnerAdd.data = action.error;
      })
      .addCase(postStonesPerformActionAsync.pending, (state) => {
        state.stonesPerformAction.status = 'loading';
      })
      .addCase(postStonesPerformActionAsync.fulfilled, (state: any, action) => {
        state.stonesPerformAction.status = 'success';
        state.stonesPerformAction.data = action.payload;
      })
      .addCase(postStonesPerformActionAsync.rejected, (state: any, action) => {
        state.stonesPerformAction.status = 'failed';
        state.stonesPerformAction.data = action.error;
      })
      .addCase(putStonesAsync.pending, (state) => {
        state.stonesMod.status = 'loading';
      })
      .addCase(putStonesAsync.fulfilled, (state: any, action) => {
        state.stonesMod.status = 'success';
        state.stonesMod.data = action.payload;
      })
      .addCase(putStonesAsync.rejected, (state: any, action) => {
        state.stonesMod.status = 'failed';
        state.stonesMod.data = action.error;
      })
      .addCase(postNewDataIntoStoneAsync.pending, (state) => {
        state.dataIntoStone.status = 'loading';
      })
      .addCase(postNewDataIntoStoneAsync.fulfilled, (state: any, action) => {
        state.dataIntoStone.status = 'success';
        state.dataIntoStone.data = action.payload;
      })
      .addCase(postNewDataIntoStoneAsync.rejected, (state: any, action) => {
        state.dataIntoStone.status = 'failed';
        state.dataIntoStone.data = action.error;
      })
      .addCase(putDataIntoStoneAsync.pending, (state) => {
        state.putDataIntoStone.status = 'loading';
      })
      .addCase(putDataIntoStoneAsync.fulfilled, (state: any, action) => {
        state.putDataIntoStone.status = 'success';
        state.putDataIntoStone.data = action.payload;
      })
      .addCase(putDataIntoStoneAsync.rejected, (state: any, action) => {
        state.putDataIntoStone.status = 'failed';
        state.putDataIntoStone.data = action.error;
      });
  },
});

export const {
  resetStonesLoad,
  resetStonesAdd,
  resetStonesOwnerAdd,
  resetStonesPerformAction,
  resetStonesMod,
  resetDataIntoStone,
  resetPutDataIntoStone,
} = stonesSlice.actions;
export const stonesLoadStatus = (state: RootState) => state.stones.stonesLoad;
export const stonesAddStatus = (state: RootState) => state.stones.stonesAdd;
export const stonesOwnerAddStatus = (state: RootState) =>
  state.stones.stonesOwnerAdd;
export const stonesPerformActionStatus = (state: RootState) =>
  state.stones.stonesPerformAction;
export const stonesModStatus = (state: RootState) => state.stones.stonesMod;
export const dataIntoStoneStatus = (state: RootState) =>
  state.stones.dataIntoStone;
export const putDataIntoStoneStatus = (state: RootState) =>
  state.stones.putDataIntoStone;
export default stonesSlice.reducer;
