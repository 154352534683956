import _ from 'lodash';

// Questa funzione suddivide il percorso in una serie di oggetti,
// ognuno contenente la "path" e la "key" nel caso in cui ci siano
// parentesi tonde nel percorso, utilizzando una regex per fare il parsing
const splitPath: any = (path: string) => {
  const matches = path.match(/\(([^)]+)\)/g);
  const pathArr = [];

  if (matches) {
    const key = matches[0]?.slice(1, -1);
    const newPath = path.slice(0, path.indexOf('('));
    pathArr.push({ path: newPath, key });

    const subPath = path.slice(path.indexOf(')') + 1);
    pathArr.push(...splitPath(subPath));
  }
  // Se non ci sono parentesi tonde nella path, ma non è vuota, allora aggiungiamo solo la path
  // all'array di output, senza la key
  else if (path && !path.endsWith('(') && !path.endsWith(')')) {
    pathArr.push({ path, key: null });
  }
  return pathArr;
};

// Questa funzione prende un array di oggetti e una chiave, e restituisce il valore dell'oggetto
// che ha la chiave corrispondente, se presente, altrimenti ritorna undefined
const loopKeyFinder = (array: any[], key: string) => {
  const items = array.flat();
  const item: any = items?.find((item: any) => {
    if(item === undefined) return;
    return (
      Object?.prototype?.hasOwnProperty?.call(item, 'key') &&
      item.key.toString() === key
    );
  });
  return item ? item.value : undefined;
};

// Questa funzione controlla se l'oggetto ha una stringa o un numero
// come valore, e in tal caso lo restituisce altrimenti, cerca la chiave
// all'interno dell'oggetto e restituisce il valore corrispondente, se presente;
// se la chiave è null, restituisce il primo elemento dell'array di output della _.at()
const checkAndReturnValue = (path: string, key: string, obj: any) => {
  if (typeof obj?.value === 'string' || typeof obj?.value === 'number') {
    return obj.value;
  }

  if (key) {
    const value = path !== '' ? _.at(obj, path) : obj;
    return loopKeyFinder(value, key);
  }

  if (key === null) {
    return _.at(obj, path)[0];
  }

  return undefined;
};

// Questa funzione effettua la ricerca ricorsiva del valore corrispondente alla path specificata,
// utilizzando la funzione checkAndReturnValue per ogni oggetto in splitted.
const recursiveFind = (splitted: any[], data: any) => {
  let result = data;
  for (let i = 0; i < splitted?.length; i++) {
    if (result) {
      result = checkAndReturnValue(splitted[i]?.path, splitted[i]?.key, result);
    } else {
      result = checkAndReturnValue(splitted[i]?.path, splitted[i]?.key, data);
    }
  }

  return typeof result?.value === 'string' ? result.value : result;
};

// Questa funzione richiama a cascata le altre e ritorna il valore finale, oppure null
const deepPathValue = (data: any[], path: string) => {
  if (typeof path !== 'string' || typeof data !== 'object') {
    throw new Error(
      'Invalid arguments: path must be a string and data must be an object'
    );
  }
  const value = recursiveFind(splitPath(path), data);
  return value !== undefined ? value : null;
};

export { deepPathValue };
