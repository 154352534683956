import moment from 'moment';
import { socketMessage } from '../../model/socketMessage';

const socketMessageParsed = (msg: any) => {
  const mp: socketMessage = JSON.parse(msg?.body);
  const mex: any = {
    code: mp?.stoneCode,
    actionExec: mp?.action,
    username: mp?.username,
    stepId: mp?.stepId,
    newStep: mp?.newStepId,
    newStatus: mp?.newStatus,
    date: moment(new Date())
      .utc()
      .tz('Europe/Rome')
      .format('DD/MM/YY · HH:mm:ss')
      .toString(),
  };
  return mex;
};

export { socketMessageParsed };
