import PolarAreaChartComponent from '../../components/charts/PolarAreaChartComponent';
import { createCounterArr } from '../../utility/stonesFilters/stonesFiltersByStep';

const PolarArea = ({ stones, steps }: any) => {
  //*-- POLAR AREA CHART DATA

  const polarAreaLegendPosition = 'top';
  const polarAreaChartTitle = 'Contatore Reperti';
  const polarAreaLabels: any = steps.map((el: any) => el?.label);
  const polarAreaBgColor: string[] = steps.map(
    (el: any) => {
      const color = el?.props?.svgProps?.color;
      return color;
    }
  );
  const dataArrayPolarArea: any = createCounterArr(
    stones,
    steps.map((el: any) => el?.id),
    'counter'
  );

  return (
    <PolarAreaChartComponent
      dataArray={dataArrayPolarArea}
      dataLabel={polarAreaLabels}
      dataBgColor={polarAreaBgColor}
      legendPosition={polarAreaLegendPosition}
      chartTitle={polarAreaChartTitle}
    />
  );
};

export default PolarArea;
