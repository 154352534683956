import { Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiForm from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { RJSFSchema } from '@rjsf/utils';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import {
  postNewUserAsync,
  resetNewUser,
  userListState,
} from '../../pages/Users/usersSlice';
import { useSelector } from 'react-redux';
import LoadingCircleComponent from '../Loading/LoadingCircleComponent';

const AddUserModalComponent = ({ open, onClose }: any) => {
  const dispatch = useAppDispatch();
  const [JSONDataModal, setJSONDataModal] = useState<RJSFSchema | any>({});
  const [schemajson, setSchemajson] = useState<RJSFSchema>();
  const [uiSchema, setUiSchema] = useState<RJSFSchema>();
  const handleCloseModal = () => {
    onClose();
  };

  const userListStatusSelector = useSelector(userListState);

  function validate(formData: any, errors: any) {
    const namesTaken = [];
    const emailsTaken = [];
    for (let i = 0; i < userListStatusSelector?.data?.length; i++) {
      namesTaken.push(userListStatusSelector?.data?.[i]?.name);
    }
    for (let i = 0; i < userListStatusSelector?.data?.length; i++) {
      emailsTaken.push(userListStatusSelector?.data?.[i]?.email);
    }

    if (namesTaken.includes(formData?.labelNome)) {
      errors.labelNome.addError('Nome già esistente!');
    }

    if (emailsTaken.includes(formData?.labelEmail)) {
      errors.labelEmail.addError('Email già esistente!');
    }

    if (formData.labelPassword !== formData.labelConfirmPassword) {
      errors.labelPassword.addError('Le password non corrispondono');
      errors.labelConfirmPassword.addError('Le password non corrispondono');
    }
    return errors;
  }
  const AddUserSchema = [
    {
      JSONSchema: {
        title: 'Aggiungi un nuovo Utente',
        type: 'object',
        required: [
          'labelNome',
          'labelEmail',
          'labelPassword',
          'labelConfirmPassword',
          'labelRole',
        ],
        properties: {
          labelNome: {
            type: 'string',
            title: 'Nome dell\'utente',
          },
          labelEmail: {
            type: 'string',
            title: 'Email',
          },
          labelPassword: {
            type: 'string',
            title: 'Password',
          },
          labelConfirmPassword: {
            type: 'string',
            title: ' Conferma Password',
          },
          labelRole: {
            type: 'array',
            uniqueItems: true,
            title: 'Ruolo',
            items: {
              enum: ['Fotografo', 'Magazziniere', 'Modellatore', 'Admin'],
            },
          },
        },
      },
      UISchema: {
        labelEmail: {
          'ui:widget': 'email',
        },
        labelPassword: {
          'ui:widget': 'password',
        },
        labelConfirmPassword: {
          'ui:widget': 'password',
        },
        'ui:submitButtonOptions': {
          props: {
            disabled: true,
            className: 'btn btn-info',
          },
          norender: false,
          submitText: 'Submit',
        },
      },
    },
  ];
  const getJSONUserModal = () => {
    setJSONDataModal(AddUserSchema);
  };
  useEffect(() => {
    getJSONUserModal();
    return () => {
      dispatch(resetNewUser());
    };
  }, []);

  useEffect(() => {
    if (JSONDataModal) {
      setSchemajson(JSONDataModal[0]?.JSONSchema);
      setUiSchema(JSONDataModal[0]?.UISchema);
    }
  }, [JSONDataModal]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleCloseModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ height: '20px', width: '98%' }}
        >
          <Grid item></Grid>
          <Grid item>
            <IconButton
              edge="end"
              onClick={() => handleCloseModal()}
              style={{ padding: '24px' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          {schemajson && uiSchema ? (
            <div>
              <MuiForm
                formContext={MuiForm}
                className={'formSchemaCSS'}
                onChange={() => {
                  if (uiSchema) {
                    Object.assign(uiSchema['ui:submitButtonOptions']?.props, {
                      disabled: false,
                      className: 'btn btn-info',
                    });
                  }
                }}
                schema={schemajson}
                uiSchema={uiSchema}
                validator={validator}
                customValidate={validate}
                onSubmit={(e) => {
                  dispatch(postNewUserAsync(e.formData));
                  handleCloseModal();
                }}
              />
            </div>
          ) : (
            <div>
              <LoadingCircleComponent size={100} />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddUserModalComponent;
