import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DateRangePickerComponent from '../DatePicker/DateRangePickerComponent';
import SearchFilterComponent from '../Search-Filter/SearchFilterComponent';
import { MixFiltersState } from '../../model/mixFiltersInterface';

const MixFiltersComponent = ({setData, data, item}: MixFiltersState) => {
  const [dateRange, setDateRange] = useState<[Date, Date] | any>(null);
  const [searchFilterString, setSearchFilterString] = useState<any>();

  const path = item?.props?.datePicker?.path;

  const convertUTC = (value: any) => {
    return moment(value).utc();
  };
  const convertUTCdmy = (value: any) => {
    return moment(value).utc().tz('Europe/Rome').format('DD-MM-YY').toString();
  };
  const equalDateValues = (values: any): any => {
    if (convertUTCdmy(values[0]) === convertUTCdmy(values[1])) return true;
    else return false;
  };

  const arrayFilteredBySearch = (array: any[], search: string) => {
    const filterSearch = array?.filter((item: any) => {
      const objStr = JSON.stringify(item)?.toLowerCase();
      if (objStr?.includes(search?.toLowerCase())) {
        return item;
      }
    });
    return filterSearch;
  };

  const filterDataMix = (
    search: string | undefined,
    date: [Date | undefined, Date | undefined]
  ) => {
    const filterByDateRange = (
      el: any,
      start: Date | undefined,
      end: Date | undefined
    ) =>
      convertUTC(_.at(el, path)?.[0]) >= convertUTC(start) &&
      convertUTC(_.at(el, path)?.[0]) <= convertUTC(end);

    const filterByEqualDate = (el: any, date: Date | undefined) =>
      convertUTCdmy(_.at(el, path)?.[0]) === convertUTCdmy(date);

    let filteredData = data;

    if (date && date[0] && date[1]) {
      if (equalDateValues(date)) {
        filteredData = data.filter((el: any) => filterByEqualDate(el, date[0]));
      } else {
        filteredData = data.filter((el: any) =>
          filterByDateRange(el, date[0], date[1])
        );
      }
    }

    if (search) {
      filteredData = arrayFilteredBySearch(filteredData, search);
    }

    setData(filteredData);
  };

  const showSearchFilter = () => {
    //if enabled by db show SearchFilter
    if (!item?.props?.searchFilter) return;
    return (
      <SearchFilterComponent
        style={{ minWidth: '60px', maxWidth: '200px' }}
        size={'small'}
        setData={setSearchFilterString}
        placeholder={'Cerca...'}
        disabled={data?.length > 0 ? false : true}
      />
    );
  };

  const showDatePicker = () => {
    //if enabled by db show SearchFilter
    if (!item?.props?.datePicker?.enabled) return;
    return (
      <DateRangePickerComponent
        defaultValue={dateRange}
        setData={setDateRange}
        size={'sm'}
        placeholder={'Filtro Date'}
        disabled={data?.length > 0 ? false : true}
        style={{ width: 'auto' }}
      />
    );
  };

  //*-------USEFFECT--------------

  //onChange DateRangePicker component triggers this useEffect
  useEffect(() => {
    if (dateRange === null) {
      setDateRange(undefined);
      setData(data);
      return;
    }
    filterDataMix(searchFilterString, dateRange);
  }, [searchFilterString, dateRange]);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1em' }}>
      {showSearchFilter()}
      {showDatePicker()}
    </div>
  );
};

export default MixFiltersComponent;
