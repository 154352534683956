const SvgComponent = ({ svgProps }: any) => {
  const size: string = svgProps?.size;
  const color: string = svgProps?.color;
  const icon: string = svgProps?.svgIcon;
  const style = {
    width: size ?? 'auto',
    height: size ?? 'auto',
    fill: color ?? 'black',
  };
  return svgProps?.svgIcon ? (
    <svg viewBox="0 0 100 100" style={style} dangerouslySetInnerHTML={{ __html: icon }}></svg>
  ) : null;
};

export default SvgComponent;