import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  ChartOptions,
  Tooltip,
  Legend,
} from 'chart.js';
import { ChartPieState } from '../../model/chartsInterface';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartComponent = ({
  dataArray,
  dataLabel,
  dataBgColor,
  legendPosition,
  chartTitle,
}: ChartPieState) => {
  const options: ChartOptions<'pie'> = {
    responsive: true,
    plugins: {
      legend: {
        position: legendPosition,
      },
      title: {
        display: chartTitle ? true : false,
        text: chartTitle,
      },
    },
  };

  const pieChartData = {
    labels: dataLabel,
    datasets: [
      {
        label: 'tot',
        data: dataArray,
        backgroundColor: dataBgColor,
        hoverOffset: 6,
      },
    ],
  };

  return dataArray && dataArray.length > 0 ? (
    <Pie data={pieChartData} options={options} />
  ) : (
    <div>Nessun dato disponibile</div>
  );
};

export default PieChartComponent;
